<template>
  <v-row justify="center">
    <v-card relative width="500" style="max-height: 98vh;overflow-y: auto;">
      <v-card-title class="text-h6">{{ $t('startShift') }}</v-card-title>
      <v-divider></v-divider>
      <v-form class="pa-5" ref="form" v-model="valid" lazy-validation>
        <v-col cols="12" sm="12">
          <h5 class="mb-5">{{ $t('changeBranch') }}</h5>
          <v-btn-toggle v-model="toggle_branch" tile color="gray lighten-5" class="d-flex mb-2 justify-center" group>
            <v-row>
              <v-btn v-for="(branchName, index) in branches" :key="index" v-on:click="branch = index" :value="index"
                class="rounded ma-1" color="gray lighten-5" elevation="0">
                <v-icon color="blue accent-4" :disabled="branch != index" left>mdi-check-circle</v-icon>
                {{ branchName }}
              </v-btn>
            </v-row>
          </v-btn-toggle>
        </v-col>

        <v-col cols="12" class="py-1">
          <h5 class="mb-3">{{ $t('finalCash') }}</h5>
            <FloatInput :value="cash" @input="cash = $event" label="" solo :required="false" :isLoading="false"
            :cols="[12, 12, 12]" />
        </v-col>
        <v-col cols="12" class="py-1">
          <h5 class="mb-3">{{ $t("caliber") }} 18</h5>
          <FloatInput :value="caliber18" @input="caliber18 = $event" label="" solo :required="false" :isLoading="false"
            :cols="[12, 12, 12]" />
        </v-col>
        <v-col cols="12" class="py-1">
          <h5 class="mb-3">{{ $t('caliber') }} 21</h5>
          <FloatInput :value="caliber21" @input="caliber21 = $event" label="" solo :required="false" :isLoading="false"
            :cols="[12, 12, 12]" />
        </v-col>
        <v-col cols="12" class="py-1">
          <h5 class="mb-3">{{ $t('caliber') }} 22</h5>
          <FloatInput :value="caliber22" @input="caliber22 = $event" label="" solo :required="false" :isLoading="false"
            :cols="[12, 12, 12]" />
        </v-col>
        <v-col cols="12" class="py-1">
          <h5 class="mb-3">{{ $t('caliber') }} 24</h5>
          <FloatInput :value="caliber24" @input="caliber24 = $event" label="" solo :required="false" :isLoading="false"
            :cols="[12, 12, 12]" />
        </v-col>
        <v-col cols="12" class="py-4">
          <v-btn color="success" large @click="startShift" :loading="loading" class="shadow" style="width: 100%">{{
            $t('startShift') }}</v-btn>
        </v-col>
      </v-form>
    </v-card></v-row>
</template>



<style scoped lang="scss"></style>



<script>
import FloatInput from '../inputs/FloatInput.vue';


export default {
    name: "StartShift",
    props: {
        branches: { type: {} },
    },
    computed: {},
    mounted() {
        // console.log("branches", this.branches);
        this.branch = Object.keys(this.branches)[0];
        this.toggle_branch = Object.keys(this.branches)[0];
    },
    watch: {},
    data() {
        return {
            dialog: true,
            valid: false,
            loading: false,
            branch: null,
            cash: 0,
            caliber18: 0,
            caliber21: 0,
            caliber22: 0,
            caliber24: 0,
            toggle_branch: 1,
        };
    },
    methods: {
        startShift() {
            this.loading = true;
            // this.$refs.form.validate()
            const requestBody = {
                "branch": Number(+this.branch),
                "summary": {
                    cash: Number(+this.cash),
                    caliber18: Number(+this.caliber18),
                    caliber21: Number(+this.caliber21),
                    caliber22: Number(+this.caliber22),
                    caliber24: Number(+this.caliber24)
                }
            };
            this.$api.POST_METHOD("start_shift", requestBody).then((response) => {
                this.loading = false;
                if (response.check) {
                    this.$api.SET_AUTH(response.data);
                    this.$router.push('/');
                }
            });
        },
    },
    components: { FloatInput }
};
</script>
