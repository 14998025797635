<template>
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="450">
         <v-card relative>
            <v-btn
               absolute
               class="mt-2"
               :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'"
               small
               fab
               color="grey lighten-1"
               text
               @click="close"
            >
               <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
            <v-card-title class="text-h6">{{ translation['used invoice summery'] }}</v-card-title>
            <v-divider></v-divider>
            <div class="pa-5">
               <v-card class="shadow rounded-lg justify-center my-2 py-5 px-5">
                  <div class="d-flex align-center justify-space-between">
                     <!-- <v-icon :color="summery.items ?   'primary' : '#9e9e9e'">mdi-check-circle-outline</v-icon> -->
                     <p class="my-0 mx-2 font-weight-medium">{{ translation['items'] }}</p>
                     <p class="my-0 mx-2" v-if="summery.items">{{ summery.items }}</p>
                  </div>
                  <v-divider class="my-3"></v-divider>

                  <div class="d-flex align-center justify-space-between">
                     <!-- <v-icon :color="summery.weight ?   'primary' : '#9e9e9e'">mdi-check-circle-outline</v-icon> -->
                     <p class="my-0 mx-2 font-weight-medium">{{ translation['weight'] }}</p>
                     <p
                        class="my-0 mx-2"
                        v-if="summery.weight"
                     >{{ $global.DecimalFormatter(Number(summery.weight).toFixed(2)) }} {{ translation['gram'] }}</p>
                  </div>
                  <v-divider class="my-3"></v-divider>

                  <div class="d-flex align-center justify-space-between">
                     <!-- <v-icon :color="summery.price ? 'primary' : '#9e9e9e'">mdi-check-circle-outline</v-icon> -->
                     <p class="my-0 mx-2 font-weight-medium">{{ translation['price'] }}</p>
                     <p
                        class="my-0 mx-2"
                        v-if="summery.price"
                     >{{ $global.DecimalFormatter(Number(summery.price).toFixed(2)) }} {{ translation['ryal'] }}</p>
                  </div>
                  <v-divider class="my-3"></v-divider>

                  <div class="d-flex align-center justify-space-between">
                     <!-- <v-icon
                        :color="summery.customerID && summery.customerName && summery.mobile ? 'primary' : '#9e9e9e'"
                     >mdi-check-circle-outline</v-icon>-->
                     <p class="my-0 mx-2 font-weight-medium">{{ translation['customer details'] }}</p>
                  </div>
                  <p
                     v-if="summery.customerID && summery.customerName && summery.mobile"
                  >{{ summery.customerName }}</p>
                  <p
                     v-if="summery.customerID && summery.customerName && summery.mobile"
                  >{{ summery.mobile }}</p>
                  <p
                     v-if="summery.customerID && summery.customerName && summery.mobile"
                  >{{ summery.customerID }}</p>
               </v-card>

               <v-col cols="12" class="py-4">
                  <v-btn
                     color="success"
                     large
                     @click="returnData"
                     class="shadow d-block mi-start-auto"
                     :loading="loading"
                     :disabled="loading"
                  >{{ translation['save invoice'] }}</v-btn>
               </v-col>
            </div>
         </v-card>
      </v-dialog>
   </v-row>
</template>



<style scoped lang="scss">
</style>



<script>
export default {
   name: "UsedInvoiceSummery",
   props: {
      backValueMethod: { type: Function },
      summery: { default: {} },
      loading: { default: false },
      dialog: { default: false },
   },
   computed: {
      translation() {
         return this.$store.state.Settings.translation;
      },
   },
   data: () => ({

  }),
   mounted() {
      window.addEventListener("keydown", e => {
         if (e.key == 'Enter' && this.dialog == true) {
            this.backValueMethod(true);
         }
      });
   },
   methods: {
      close() {
         this.dialog = false
         this.backValueMethod(false);
      },
      returnData() {
         this.dialog = false
         this.backValueMethod(true);
      },
   },
};
</script>
