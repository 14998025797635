<template>
  <v-card elevation="0" class=" justify-center my-3">
    <v-col cols="6" sm="12" class="px-sm-3 pb-0 d-flex align-center">
      <img src="@/assets/img/svg/16405049131582960209.svg" alt="Qarat-POS" class="me-2" height="30" />
      <h4>
        {{ $t('sales inside shift') }}
      </h4>
    </v-col>
    <v-col cols="12" sm="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(header, index) in paymentsTableHeader" :key="index">{{ $t(header.text) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(payment, index) in payment_methods" :key="index" :class="{ 'cursor_pointer': payment.method }"
              @click="getDetails(payment.method)">
              <td v-for="(header, headerIndex) in paymentsTableHeader" dir="ltr" :key="headerIndex">
                <template v-if="header.type == 'float'">
                  <template v-if="payment[header.key] >= 0">{{ payment[header.key] || 0 | float }}</template>
                  <template v-else>({{ Math.abs(payment[header.key]) || 0 | float }})</template>
                </template>
                <template v-else>
                  <v-icon v-if="detailsLoading && detailsType === payment.method" size="18" color="blue1"
                    class="mdi-spin">mdi-refresh</v-icon>
                  {{ $t(payment[header.key]) }}</template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row>
        <v-col cols="6">
          <v-card color="light" class="py-2 px-4 mt-3 d-flex justify-space-between subtitle-2 font-weight-bold cardBtn"
            outlined width="auto" @click="getDetails('all')">
            <div> {{ $t('actions inside your shift') }}</div>
            <div>
              <v-icon v-if="detailsLoading && detailsType === 'all'" size="18" color="blue1"
                class="mdi-spin">mdi-refresh</v-icon>
            </div>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card color="light" class="py-2 px-4 mt-3 d-flex justify-space-between subtitle-2 font-weight-bold cardBtn"
            outlined width="auto" @click="getDetails('sales_invoices')">
            <div> {{ $t('final sales') }}</div>
            <div> {{ payment_methods?.at(-1).final || 0 | float }}
              <v-icon v-if="detailsLoading && detailsType === 'sales_invoices'" size="18" color="blue1"
                class="mdi-spin">mdi-refresh</v-icon>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>



    <v-dialog v-model="showDetails" min-width="1200">
      <v-card class="overflow-hidden" :max-height="$store.state.Settings.windowSize.y - 25">
        <v-card-title class="subtitle-1 font-weight-bold light">
          {{ $t(detailsType == 'all' ? 'actions inside your shift' : 'sales') }}
          <v-icon v-if="detailsLoading" size="18" color="blue1" class="mdi-spin ms-1 mt-n2">mdi-refresh</v-icon>
        </v-card-title>

        <v-card flat>
          <v-simple-table fixed-header :height="$store.state.Settings.windowSize.y - 200">
            <template v-slot:default>
              <thead class="light">
                <tr class="light">
                  <th class="light" v-for="(header, index) in detailsTableHeader" :key="index">{{ $t(header.text) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(detail, index) in details" :key="index">
                  <td v-for="(header, headerIndex) in detailsTableHeader" :key="headerIndex"
                    :class="{ 'red12': detail.sign == '-' }" dir="ltr">
                    <template v-if="header.type == 'float' || header.type == 'weight'">
                      {{ detail.sign == '-' && detail[header.key] ? '( ' : '' }}
                      {{ detail[header.key] || 0 | nonZero }}
                      {{ detail.sign == '-' && detail[header.key] ? ' )' : '' }}
                    </template>
                    <template v-else>{{ $t(detail[header.key]) }}</template>
                  </td>
                </tr>
                <tr class="light" style="position : sticky; bottom : 0">
                  <th v-for="(header, index) in detailsTableHeader" :key="index" class="light">
                    <template v-if="header.key == 'm_id'">
                      {{ $t('total') }}
                    </template>
                    <template v-if="header.key == 'type_title'">
                      {{ details.length }} {{ $t('action') }}
                    </template>
                    <template v-else-if="header.type == 'float' || header.type == 'weight'">
                      {{ calculateSum(details, header.key) | nonZero }}
                    </template>
                  </th>
                </tr>
              </tbody>

            </template>
          </v-simple-table>
        </v-card>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="() => showDetails = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
export default {
  name: "PaymentMethodsStatistics",
  computed: {
    paymentImage() {
      return (method) => {
        switch (method) {
          case 'cash': return require('@/assets/img/payment/cash.svg');
          case 'bank': return require('@/assets/img/payment/bank.svg');
          case 'trans': return require('@/assets/img/payment/transfer.svg');
          case 'tabby': return require('@/assets/img/payment/tabby.svg');
          case 'tamara': return require('@/assets/img/payment/tamara.svg');
          case 'nearpay': return require('@/assets/img/payment/cash.svg');
          case 'stc': return require('@/assets/img/payment/stc.svg');
          case 'total': return require('@/assets/img/payment/cash.svg');
          default: return '';
        }
      }
    },
    paymentsTableHeader() {
      return [
        { text: "method", key: "method", type: "text" },
        { text: "sales", key: "sales", type: "float" },
        { text: "notice", key: "notice", type: "float" },
        { text: "final", key: "final", type: "float" },
      ]
    },
    detailsTableHeader() {
      return [
        { text: "#", key: "m_id", type: "text" },
        { text: "action", key: "type_title", type: "text" },
        { text: "cash", key: "paid_cash", type: "float" },
        { text: "bank", key: "paid_bank", type: "float" },
        { text: "trans", key: "paid_trans", type: "float" },
        { text: "tabby", key: "paid_tabby", type: "float" },
        { text: "tamara", key: "paid_tamara", type: "float" },
        { text: "stc", key: "paid_stc", type: "float" },
        { text: "nearpay", key: "paid_nearpay", type: "float" },
        { text: "total amount", key: "final_total", type: "float" },
        { text: "caliber 18", key: "weight_18", type: "weight" },
        { text: "caliber 21", key: "weight_21", type: "weight" },
        { text: "caliber 22", key: "weight_22", type: "weight" },
        { text: "caliber 24", key: "weight_24", type: "weight" },
        { text: "total weight", key: "total_weight", type: "float" },
      ]
    },
    calculateSum() {
      return (DATA, KEY) => {
        return DATA.reduce((oldValue, newValue) => {
          if (KEY in newValue) return newValue.sign == '-' ? (+oldValue - +newValue[KEY]) : (+oldValue + +newValue[KEY])
          else return +oldValue
        }, 0);
      }
    },
  },
  watch: {
    detailsType() {
      this.details = []
    }
  },
  props: {
    payment_methods: { type: [] },
  },
  data: () => ({
    showDetails: false,
    detailsLoading: false,
    detailsType: 'all',
    details: [],
    shiftDetails: [],
  }),
  methods: {
    getDetails(detailsType) {
      if (this.shiftDetails.length) {
        this.prepareDetails(detailsType);
      } else {
        if (!this.detailsLoading) {
          this.detailsLoading = true;
          this.detailsType = detailsType;
          this.$api.POST_METHOD(`shift_report`, { shift_id: this.$store.state.userData?.user?.shift?.shift_id }, false).then(({ check, data }) => {
            this.detailsLoading = false;
            if (check) {
              this.shiftDetails = data?.data || [];
              this.setDetailType();
              this.prepareDetails(detailsType);
            }
          })
        }
      }
    },
    setDetailType() {
      this.shiftDetails.forEach(detail => {
        switch (detail.type) {
          case 'sales': detail.type_title = this.$t('sales invoice'); break;
          case 'u_pr': detail.type_title = this.$t('used purchases'); break;
          case 'nc': detail.type_title = this.$t('notice creditor'); break;
          case 'nd': detail.type_title = this.$t('notice debtor'); break;
          case 'mo_t': detail.type_title = this.$t('money transfer'); break;
          case 'me_t': detail.type_title = this.$t('metal transfer'); break;
          case 'p_v': detail.type_title = this.$t('payment voucher'); break;
          case 'r_v': detail.type_title = this.$t('receipt voucher'); break;
          case 'p_g_p': detail.type_title = this.$t('payment gold permission'); break;
          case 'r_g_p': detail.type_title = this.$t('receipt gold permission'); break;
          case 'deposits': detail.type_title = this.$t('deposits'); break;
          case 'expenses': detail.type_title = this.$t('expenses'); break;
          default: detail.type_title = this.$t(detail.type); break;
        }
      });
    },
    prepareDetails(detailsType) {
      let filteredData = this.shiftDetails.filter(({ type }) => detailsType == 'all' ? true : (type == 'sales' || type == 'nc' || type == 'nd')) || [];
      switch (detailsType) {
        case 'cash': this.details = filteredData.filter(({ paid_cash }) => paid_cash); break;
        case 'bank': this.details = filteredData.filter(({ paid_bank }) => paid_bank); break;
        case 'trans': this.details = filteredData.filter(({ paid_trans }) => paid_trans); break;
        case 'nearpay': this.details = filteredData.filter(({ paid_nearpay }) => paid_nearpay); break;
        case 'tamara': this.details = filteredData.filter(({ paid_tamara }) => paid_tamara); break;
        case 'tabby': this.details = filteredData.filter(({ paid_tabby }) => paid_tabby); break;
        case 'stc': this.details = filteredData.filter(({ paid_stc }) => paid_stc); break;
        case 'sales_invoices': this.details = filteredData.filter(({ type }) => type == 'sales' || type == 'nc' || type == 'nd'); break;
        case 'total':
        case 'all': this.details = filteredData; break;
        default: this.details = []; break;
      }
      this.details.length ? this.showDetails = true : this.$api.ShowSnack('info', 'no data available');
    }
  }
};
</script>

<style scoped lang="scss">
thead {
  tr {
    background-color: #E8E8EE !important;

    &:hover {
      background-color: #E8E8EE !important;
    }
  }
}

tr {
  &:hover {
    background: transparent !important;
  }

  &:nth-of-type(even) {
    background-color: #F1F1F3;
  }

  &:last-of-type {
    background-color: #E8E8EE;

    &:hover {
      background-color: #E8E8EE !important;
    }
  }
}

td,
th {
  border-bottom: 0 !important;
  height: 12px !important;
  padding: 3.8px 16px !important;
  text-align: center !important;
}

.cardBtn {
  transition: .35s;

  &:hover {
    background: var(--blue3) !important;
    color: #fff !important;

    i {
      color: #fff !important;
    }
  }
}
</style>