import { render, staticRenderFns } from "./ReportTotals.vue?vue&type=template&id=116d374f&scoped=true&"
import script from "./ReportTotals.vue?vue&type=script&lang=js&"
export * from "./ReportTotals.vue?vue&type=script&lang=js&"
import style1 from "./ReportTotals.vue?vue&type=style&index=1&id=116d374f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "116d374f",
  null
  
)

export default component.exports