<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" content-class="overflow-hidden" persistent :max-width="700">
      <v-card relative >

         <v-form ref="detail"  class="pa-2">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row>
                  <v-col cols="12" class="pt-0">
                     <div class="text-h6 font-weight-bold d-flex align-center">
                        <span class="mx-2">
                           {{ $t('duration') }}
                        </span>
                     </div>
                  </v-col>

                  <v-col cols="6">
                     <label class="subtitle-2 gray8--text">{{ $t('from') }}</label>
                     <GenericInput type="date" :solo="true" :dense="true" classes="rounded-lg"
                        :isPickerOpened="() => filter.to_date = null" :maxDate="filter.to_date || filter.today" :value="filter.from_date"
                        @input="filter.from_date = $event" label="from" :required="false" :hide-details="true"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>
                  <v-col cols="6">
                     <label class="subtitle-2 gray8--text">{{ $t('to') }}</label>
                     <GenericInput type="date" :solo="true" :dense="true" classes="rounded-lg" :value="filter.to_date"
                        @input="filter.to_date = $event" label="to" :minDate="filter.from_date" :maxDate="filter.today" :required="false"
                        :hide-details="true" :cols="[12, 12, 12]"></GenericInput>
                  </v-col>
                  <v-col cols="12" class="">
                     <div class="text-h6 font-weight-bold d-flex align-center">
                        <span class="mx-2">
                           {{ $t('differences') }}
                        </span>
                     </div>
                  </v-col>
                  <v-col cols="6">
                     <GenericInput type="checkbox" :solo="true" :value="filter.negative_difference"
                        @input="filter.negative_difference = $event" label="negative difference" :required="false"
                        :hide-details="true" :cols="[12, 12, 12]"></GenericInput>
                  </v-col>
                  <v-col cols="6">
                     <GenericInput type="checkbox" :solo="true" :value="filter.positive_difference"
                        @input="filter.positive_difference = $event" label="positive difference" :required="false"
                        :hide-details="true" :cols="[12, 12, 12]"></GenericInput>
                  </v-col>


                  <v-col :cols="12" class="d-flex justify-end">
                     <v-btn text depressed color="error" class="mx-1" :min-width="120" @click="closeDialog" :height="37">
                        <v-icon left class="mt-1">mdi-close</v-icon>
                        {{ $t('close') }}
                     </v-btn>
                     <v-btn depressed color="primary" class="mx-1" :min-width="120" @click="save" :height="37">
                        {{ $t('filter') }}
                     </v-btn>

                  </v-col>
               </v-row>
            </v-card>
         </v-form>





      </v-card>
   </v-dialog>
</template>

<script>
import GenericInput from '@/components/GenericInput.vue';

export default {
   name: "TransferFilter",
   props: {
      filterAction: { type: Function },
      closeDialog: { type: Function },
      dialog: { default: false },
   },
   computed: {

   },
   components: { GenericInput },
   watch: {
      dialog() {

      }
   },
   data: () => ({
      filter: {
         from_date: null,
         to_date: null,
         today: null,
         negative_difference: null,
         positive_difference: null,
      }
   }),
   methods: {
      save() {
         this.filterAction(this.filter)
      },
   },
   mounted() {
      this.filter.today = this.$global.GetCurrentDate();
      this.filter.to_date = this.$global.GetCurrentDate();
      this.filter.from_date = this.$global.GetFirstDayOfMonth();
   },
};
</script>