<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000">
      <v-card relative>
        <v-btn absolute class="mx-2 mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click="backValueMethod">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h6">
          {{ $t('invoice') }}
          <span class="mx-2 primary--text">{{ invoice_id }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-5">

          <v-tabs v-model="tab">
            <v-tab>{{ $t('invoice details') }}</v-tab>
            <v-tab v-if="invoice.id_photo || invoice.item_photo">{{ $t('attachments') }}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <ControlSummery :summery="summery" :loading="isLoading" />
            </v-tab-item>
            <v-tab-item>
              <v-row justify="start" align="center">
                <v-col cols="6" v-if="invoice.id_photo">
                  <a :href="$store.state.endpointURL + invoice.id_photo" target="_blank"
                        download>
                  <v-card class="pa-2 my-3 shadow" height="300px" width="400px">
                    <v-card-title class="subtitle-1">{{ $t('identity image') }}

                      <a :href="$store.state.endpointURL + invoice.id_photo" class="blue8--text text-decoration-underline mx-2" target="_blank"
                        download>{{ $t('show') }}</a>
                      </v-card-title>
                    <img :src="$store.state.endpointURL + invoice.id_photo" class="d-block ma-auto"
                      style="max-height: 200px;max-width: 100%;" alt="">
                  </v-card>
                  </a>
                </v-col>
                <v-col cols="6" v-if="invoice.item_photo">
                  <a :href="$store.state.endpointURL + invoice.item_photo" target="_blank"
                  download>
                  <v-card class="pa-2 my-3 shadow" height="300px" width="400px">
                    <v-card-title class="subtitle-1">{{ $t('item image') }}
                      <a :href="$store.state.endpointURL + invoice.item_photo" class="blue8--text text-decoration-underline mx-2" target="_blank"
                        download>{{ $t('show') }}</a>
                      </v-card-title>
                    <img :src="$store.state.endpointURL + invoice.item_photo" class="d-block ma-auto"
                      style="max-height: 200px;max-width: 100%;" alt="">
                  </v-card>
                  </a>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>


          <v-col cols="12" class="d-flex justify-end">
            <v-btn :width="120" color="error" outlined class="d-block mx-2" @click="backValueMethod">
              {{ $t('close') }}
            </v-btn>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>






<script>
import ControlSummery from '@/components/ui/ControlSummery.vue';

export default {
  name: "SalesInvoiceModal",
  props: {
    backValueMethod: { default: Function },
    dialog: { default: false },
    invoice_id: { default: null },
  },
  computed: {

  },
  watch: {
    dialog() {
      if (this.dialog && this.invoice_id) {
        this.getInvoice()
      } else {
        console.log('else');
      }
    },
  },
  mounted() {

  },
  data: () => ({
    isLoading: false,
    invoice: Object,
    summery: [],
    tab: 0,
  }),
  methods: {
    getInvoice() {
      this.isLoading = true;
      this.$api.GET_METHOD(`get_used_invoice/${this.invoice_id}`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.invoice = response.data.item;
          this.summery = [
            {
              label: 'invoice date',
              value: response.data.item.action_date,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'customer',
              value: response.data.item.customer_name,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'customer mobile',
              value: response.data.item.customer_mobile,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'customer email',
              value: response.data.item.customer_email,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'caliber',
              value: response.data.item.category_title,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'weight',
              value: response.data.item.weight,
              type: 'float',
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'items',
              value: response.data.item.items,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'count',
              value: response.data.item.count_items,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'branch',
              value: response.data.item.branch_name,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'hijri',
              value: response.data.item.birth_date_h,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'georgian',
              value: response.data.item.birth_date_m,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'total price',
              value: response.data.item.total_price,
              class: '',
              type: 'float',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'description',
              value: response.data.item.description,
              class: '',
              cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
            },
          ]
        } else {
          this.backValueMethod();
        }
      })
    },

  },
  components: { ControlSummery }
};
</script>

<style scoped lang="scss"></style>