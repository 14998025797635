import { render, staticRenderFns } from "./CustomerWithdrawals.vue?vue&type=template&id=30ae44e0&scoped=true&"
import script from "./CustomerWithdrawals.vue?vue&type=script&lang=js&"
export * from "./CustomerWithdrawals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30ae44e0",
  null
  
)

export default component.exports