<template>
  <div ref="numPad" class="numberPad">
    <v-text-field
      hide-details="auto"
      class="my-4 rounded-lg grey lighten-5"
      :filled="activeInput == true ? false : true"
      :outlined="activeInput == true ? true : false"
      :autofocus="autofocus"
      @blur="(e) => inputStatus(e)"
      @focus="(e) => inputStatus(e)"
      @keydown.enter="(e) => clickEnter(e.target.value)"
      @change="(e) => backValueMethod(e.target.value)"
      rounded
      color="#cccccc"
      :readonly="disabled"
      dense
      :value="number == false ? $global.DecimalFormatter(Number(value).toFixed(2)) != 'NaN' ? $global.DecimalFormatter(Number(value).toFixed(2)) : $global.DecimalFormatter(Number(0.00).toFixed(2)) : value"
    ></v-text-field>
    <v-col cols="12" class="rounded-lg my-5 grey lighten-3">
      <v-row>
        <v-col cols="4" class="pa-0">
          <v-btn v-on:click="onInput(9)" elevation="0" :disabled="disabledNumber">9</v-btn>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-btn v-on:click="onInput(8)" elevation="0" :disabled="disabledNumber">8</v-btn>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-btn v-on:click="onInput(7)" elevation="0" :disabled="disabledNumber">7</v-btn>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-btn v-on:click="onInput(6)" elevation="0" :disabled="disabledNumber">6</v-btn>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-btn v-on:click="onInput(5)" elevation="0" :disabled="disabledNumber">5</v-btn>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-btn v-on:click="onInput(4)" elevation="0" :disabled="disabledNumber">4</v-btn>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-btn v-on:click="onInput(3)" elevation="0" :disabled="disabledNumber">3</v-btn>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-btn v-on:click="onInput(2)" elevation="0" :disabled="disabledNumber">2</v-btn>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-btn v-on:click="onInput(1)" elevation="0" :disabled="disabledNumber">1</v-btn>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-btn v-on:click="onDelete()" elevation="0"  color="danger">
            <span style="font-size: 12px; color: red">{{ $t('delete') }}</span>
          </v-btn>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-btn v-on:click="onInput(0)" :disabled="disabledNumber" elevation="0">
            <small class="text-h5">0</small>
          </v-btn>
        </v-col>
        <v-col cols="4" class="pa-0" v-if="disabledDot == false">
          <v-btn :disabled="(includeDot == true || disabledNumber)" v-on:click="onInput('.')" elevation="0">.</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>


<script>
export default {
  name: "NumberPad",
  props: {
    backValueMethod: { type: Function },
    clickEnter: { type: Function },
    value: { default: "" },
    disabled: { default: true },
    disabledDot: { default: false },
    number: { default: false },
    autofocus: { default: true },
    activePad: { default: '' }
  },
  data: () => ({
    maxLength: 24,
    blurred: false,
    isActive: false,
    includeDot: false,
    activeInput: true,
  }),
  computed: {
    translation() {
      return this.$store.state.Settings.translation;
    },
    disabledNumber(){
      return String(this.value.split('.').splice(1)).length  == 2
    }
  },
  watch: {
    activePad() {
      this.isActive = this.activePad;
      // console.log(this.isActive);
    },
    value() {
      this.includeDot = this.value.includes(".")
    }
  },
  mounted() {
    this.backValueMethod(this.value);
    this.$el.addEventListener("keypress", e => {
      if ((Number(e.key) || e.key == '.' || e.key == '0') && this.disabled == true) {
        this.onInput(e.key)
      }
    });
    this.$el.addEventListener("keydown", e => {
      if (e.code == "Backspace" && this.disabled == true) {
        this.onDelete()
      }
    });
  },
  beforeDestroy: function () {
    this.$el.removeEventListener('click', this.onClick)
  },
  methods: {
    onClick: function (ev) {
      console.log(ev.offsetX, ev.offsetY)
    },
    onInput(key) {
      if (!(key == "." && this.value.includes("."))) {
        this.value = (this.value + key).slice(0, this.maxLength);
        this.backValueMethod(this.value);
      }
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
      this.backValueMethod(this.value);
    },
    onReset() {
      this.value = "";
      this.backValueMethod(this.value);
      this.backValueMethod(this.value);
    },
    setCode(code) {
      // console.log("code", code);
      this.backValueMethod(code);
      this.clickEnter(code)
      this.value = code
    },
    inputStatus(e) {
      console.log(e);
      switch (e.type) {
        case 'blur':
          this.activeInput = false
          break;
        case 'focus':
          this.activeInput = true
          break;

        default:
          break;
      }
    }
  },
};
</script>



<style scoped lang="scss">
button {
  width: inherit;
  border-radius: 0;
  background-color: transparent !important;
  font-size: 26px !important;
  height: 50px !important;
  border: 1px solid #ccc;
}
.v-application--is-rtl {
  .col-4:nth-of-type(1) button,
  .col-4:nth-of-type(2) button,
  .col-4:nth-of-type(3) button {
    border-top: 0;
  }
  .col-4:nth-of-type(10) button,
  .col-4:nth-of-type(11) button,
  .col-4:nth-of-type(12) button {
    border-bottom: 0;
  }
  .col-4:nth-of-type(3) button,
  .col-4:nth-of-type(6) button,
  .col-4:nth-of-type(9) button,
  .col-4:nth-of-type(12) button {
    border-left: 0;
  }
  .col-4:nth-of-type(1) button,
  .col-4:nth-of-type(4) button,
  .col-4:nth-of-type(7) button,
  .col-4:nth-of-type(10) button {
    border-right: 0;
  }
}
.v-application--is-ltr {
  .col-4:nth-of-type(1) button,
  .col-4:nth-of-type(2) button,
  .col-4:nth-of-type(3) button {
    border-top: 0;
  }
  .col-4:nth-of-type(10) button,
  .col-4:nth-of-type(11) button,
  .col-4:nth-of-type(12) button {
    border-bottom: 0;
  }
  .col-4:nth-of-type(3) button,
  .col-4:nth-of-type(6) button,
  .col-4:nth-of-type(9) button,
  .col-4:nth-of-type(12) button {
    border-right: 0;
  }
  .col-4:nth-of-type(1) button,
  .col-4:nth-of-type(4) button,
  .col-4:nth-of-type(7) button,
  .col-4:nth-of-type(10) button {
    border-left: 0;
  }
}
</style>
