<template>
  <v-container fluid class="d-flex justify-center px-0">
    <v-col cols="12" xl="10" lg="10" md="11" sm="12" class="white shadow rounded-lg">
      <v-row align="center" justify-lg="center" justify="start">
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/filled.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('Sales invoices') }}</h6>
              <p class="my-0">
                <strong dir="ltr"> {{ summary.countSalesInvoices || 0 | float }}</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="pa-0 d-md-block d-none">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/Group 712.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('purchase invoice') }}</h6>
              <p class="my-0">
                <strong dir="ltr">{{ summary.countUsedInvoices || 0 | float }}</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col cols="auto" class="pa-0 d-md-block d-none">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/filled.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('final cash drawer') }}</h6>
              <p class="my-0">
                <strong> {{ $store.state.userData.user.shift.first_cash || 0 | float }}</strong>
              </p>
            </v-col>
          </v-row>
        </v-col> -->
        <v-col cols="auto" class="pa-0 ">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/filled.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('total sales') }}</h6>
              <p class="my-0">
                <strong dir="ltr"> {{ Number(summary.final.totalSalesCash) +  Number(summary.final.totalSalesMachine) || 0 | float }}</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="pa-0 d-md-block d-none">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/Group 710.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('totalSalesCash') }}</h6>
              <p class="my-0">
                <strong dir="ltr">{{ summary.final.totalSalesCash || 0 | float }}</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="pa-0 ">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/makena.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('totalSalesMachine') }}</h6>
              <p class="my-0">
                <strong dir="ltr">{{ summary.final.totalSalesMachine || 0 | float }}</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/returnSalesIcon.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('total notice debtor') }}</h6>
              <p class="my-0">
                <strong dir="ltr"> {{ summary.countNoticeDebtor || 0 | float }}</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="pa-0 ">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/returnSalesIcon.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('total notice creditor') }}</h6>
              <p class="my-0">
                <strong dir="ltr"> ({{ summary.countNoticeCreditor || 0 | float }})</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="pa-0 d-md-block d-none">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>

        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/returnSalesIcon.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('total expenses') }}</h6>
              <p class="my-0">
                <strong dir="ltr"> ({{ summary.countExpense || 0 | float }})</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/Group 708.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('total deposits') }}</h6>
              <p class="my-0">
                <strong dir="ltr"> {{ summary.final.totalDeposits || 0 | float }}</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="pa-0 d-md-block d-none">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/returnSalesIcon.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('total money transfer') }}</h6>
              <p class="my-0">
                <strong>{{ summary.countTransCash || 0 | float }}</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="pa-0 ">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/Group 708.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('totalUsedCash') }}</h6>
              <p class="my-0">
                <strong dir="ltr">({{ summary.final.totalUsedCash || 0 | float }})</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="auto" class="pa-0 d-md-block d-none">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>


        <v-col cols="auto" class="pa-0 d-md-block d-none">
          <v-divider class="d-inline" vertical inset="inset"></v-divider>
        </v-col>
        <v-col cols="6" md="auto">
          <v-row>
            <v-col cols="auto">
              <img src="../assets/img/svg/16405049131582960209.svg" alt="Qarat-POS" />
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('finalTotalCash') }}</h6>
              <p class="my-0">
                <strong v-if="summary.final.finalCash >= 0">{{ summary.final.finalCash || 0 | nonZero }}</strong>
                <strong dir="ltr" v-if="summary.final.finalCash < 0">({{ summary.final.finalCash || 0 | float }})</strong>
              </p>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </v-col>
  </v-container>
</template>








<script>
export default {
  name: "StatisticsBar",
  props: ["summary"],
  computed: {
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
};
</script>
