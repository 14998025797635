<template>
   <div>

      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" filename="كشف حساب  عيارات" :pdf-quality="3"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :maxPDFRows="35" :data="printData" :header_slot="false" :content_slot="false"
               :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="Object">

               <template v-slot:pdf_content>

                  <div class="print_title">
                     <div> {{ type == 1 ? 'صـرف مـعـدن مشغول' : 'قبض مـعـدن مشغول' }}</div>
                     <div> {{ type == 1 ? 'METAL PAYMENT' : 'METAL RECEIPT' }}</div>
                  </div>


                  <div style="display: flex;justify-content: space-between;">
                     <table class="metal_voucher master" style="width:68% !important">
                        <tr>
                           <td>{{ $t('account number') }}</td>
                           <td>{{ '-' }}</td>
                           <td style="background: #e6ecff !important;">رقم الفاتورة /.No I</td>
                           <td>{{ printStaticData.id }}</td>
                        </tr>
                        <tr>
                           <td>
                              {{ printStaticData.supplier_id ? 'اسم المورد' : 'اسم العميل' }}
                              <br />
                              {{ printStaticData.supplier_id ? 'Supplier Name' : 'Customer Name' }}
                           </td>
                           <td colspan="3">{{ printStaticData.supplier_name || printStaticData.customer_name || '-' }}
                           </td>
                        </tr>
                        <tr>
                           <td>
                              {{ $t('mobile number') }}
                           </td>
                           <td colspan="3">
                              {{ printStaticData.supplier_mobile || printStaticData.customer_mobile || '-' }} 
                           </td>
                        </tr>
                        <tr>
                           <td>
                              {{ $t('vat number') }}
                           </td>
                           <td colspan="3">
                              {{ printStaticData.supplier_vat_register_number ||
                                 printStaticData.customer_vat_register_number || '-' }}
                           </td>
                        </tr>
                     </table>
                     <table class="metal_voucher" style="width:30% !important">
                        <tr>
                           <td>{{ 'التاريخ' }}</td>
                           <td>{{ printStaticData.add_date | dateAr }}</td>
                           <td>{{ 'Date' }}</td>
                        </tr>
                        <tr>
                           <td>{{ 'الموافق' }}</td>
                           <td>{{ '-' }}</td>
                           <td>{{ 'Date H' }}</td>
                        </tr>
                        <tr>
                           <td>{{ 'الوقت' }}</td>
                           <td>{{ printStaticData.add_date | selectTime }}</td>
                           <td>{{ 'Time' }}</td>
                        </tr>
                     </table>
                  </div>


                  <table class="metal_voucher">
                     <tr>
                        <th>
                           No <br /> م
                        </th>
                        <th>
                           STOCK NAME
                           <br />
                           اسم الصنف
                        </th>
                        <th>
                           WEIGHT
                           <br />
                           الوزن
                        </th>
                        <th>
                           PURITY
                           <br />
                           العيار
                        </th>
                        <th>
                           Making Charge
                           <br />
                           سعر الاجور
                        </th>
                        <th>
                           Total Making
                           <br />
                           اجمالي الاجور
                        </th>
                     </tr>

                     <tr>
                        <td>{{ 1 }}</td>
                        <td>{{ printStaticData.sub_category_name }}</td>
                        <td>{{ printStaticData.weight | nonZero }}</td>
                        <td>{{ printStaticData.category_name || '-' }}</td>
                        <td>{{ printStaticData.wages | nonZero }}</td>
                        <td>{{ printStaticData.total_wages  | nonZero }}</td>
                     </tr>
                  </table>

                  <table class="metal_voucher">
                     <tr>
                        <th>
                           البيان / Des
                        </th>
                     </tr>
                     <tr>
                        <td style="background-color: #f8f9fd !important;">
                           {{ printStaticData.description || '-' }}
                        </td>
                     </tr>
                  </table>


                  <div class="print_title">
                     TRANSCATION SUMMARY / الملخص
                  </div>
                  <table class="metal_voucher">
                     <tr>
                        <th>
                           Silver
                        </th>
                        <th>
                           24k
                        </th>
                        <th>
                           22k
                        </th>
                        <th>
                           21k
                        </th>
                        <th>
                           18k
                        </th>
                     </tr>
                     <tr>
                        <td style="background-color: #f8f9fd !important;">
                           {{ printStaticData.category_id == 925 ? printStaticData.weight : 0 | nonZero }}
                        </td>
                        <td>
                           {{ printStaticData.category_id == 24 ? printStaticData.weight : 0 | nonZero }}
                        </td>
                        <td>
                           {{ printStaticData.category_id == 22 ? printStaticData.weight : 0 | nonZero }}
                        </td>
                        <td>
                           {{ printStaticData.category_id == 21 ? printStaticData.weight : 0 | nonZero }}
                        </td>
                        <td>
                           {{ printStaticData.category_id == 18 ? printStaticData.weight : 0 | nonZero }}
                        </td>
                     </tr>
                  </table>


               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>
   </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import PrintPDF from '@/components/PrintPDF.vue';
export default {
   name: "VoucherMetal",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: [] },
      printStaticData: { default: Object },
      title: { default: '' },
      type: { default: '' },
      footerData: { default: Object },
   },
   computed: {

   },
   components: {
      VueHtml2pdf,
      PrintPDF
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
         }
      },
   },
   data: () => ({
      tableHeader: [],
   }),
   methods: {

   },
   mounted() {

   },

};
</script>

<style lang="scss" >
.metal_voucher {
   margin: 20px 0;
   border-collapse: collapse;
   border: 2px solid #e9e8ee;
   //border-bottom: 8px solid #28aae1;
   //border-right: 8px solid #28aae1;
   background: #fff !important;
   width: 100%;

   tr {
      th {
         z-index: 2;
         position: relative;
         padding: 5px !important;
         background: #e9e8ee !important;
         border: 1px solid #ddd !important;
         font-size: 11px !important;
      }

      td {
         padding: 5px !important;
         // font-weight: 600;
         border-bottom: 1px solid #ddd !important;
         font-size: 12px !important;
         background: #f8f9fd !important;
         border-left: 1px solid #eee;
         text-align: center !important;
         &:nth-of-type(1) {
            // color: #666 !important;
            background: #e6ecff !important;
            padding: 5px !important;
            text-align: center !important;
         }

         &:nth-of-type(2) {
            text-align: center !important;
            color: #333 !important;
         }
      }
   }

   &.master {
      td {
         width: 25% !important;
      }
      tr:nth-of-type(1){
         td:nth-of-type(3){
            background: #e6ecff !important;
         }
      }
   }
}
</style>