<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <div class="subtitle-2 text--disabled">
            <strong>{{ $t('deposits') }} . {{ $t("invoiceList") }}</strong>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="controlRoute"
            createBtnLabel="create deposit invoice" :data="rows" :header="header">
            <template v-slot:td="{ row }">
              <div>
                <v-btn v-if="$global.CheckAction('06-023', 5)" @click="printDeposit(row)" :loading="printLoading" class="pa-0" width="20" height="20"  x-small icon fab>
                  <img src="@/assets/img/svg/pdf.svg" alt="Qarat-POS" />
                </v-btn>
                <v-btn @click="edit(row)" class="blue12 ma-1 mx-2" x-small icon fab>
                  <v-icon color="blue1">mdi-eye-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" class="py-0" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col>
      </v-row>
    </v-container>
    <DepositsPrint :print="printDepositNow" :printAction="() => { printDepositNow = false; }"
      :printStaticData="printDepositMaster" :printData="[]" />
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import Pagination from "@/components/Pagination";
import DepositsPrint from "./DepositsPrint.vue";
export default {
  name: "DepositsTable",

  data: () => ({
    isLoading: true,
    controlRoute: '/deposits/create-deposit',
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 25,
    searchText: '',
    from: null,
    to: null,
    date: null,
    printLoading: false,
    printDepositNow: false,
    printDepositMaster: {},
  }),
  components: {
    DynamicTable,
    Pagination,DepositsPrint
  },
  computed: {},
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "invoice number", key: "id", type: 'text', classes: "" },
        { text: "description", key: "description", type: 'text', classes: "" },
        { text: "deposit amount", key: "amount", type: 'price', classes: "" },
        { text: "tax", key: "tax_name", type: 'text', classes: "" },
        { text: "date", key: "action_date", type: 'date', classes: "" },
        { text: "branch", key: "branch_name", type: 'text', classes: "" },
        { text: "treasury", key: "treasury_name", type: 'text', classes: "" },
        { text: "added by", key: "user_full_name", type: 'text', classes: "" },
        { text: "", key: "id", type: 'slot', hideInPrint: true, classes: "" },
      ]
    },
    getData() {
      this.isLoading = true;
      if (this.$global.CheckAction("06-023", 1)) {
        this.$api.GET_METHOD(`deposit?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText || ''}&date=${this.date || ''}`).then((response) => {
          this.isLoading = false;
          this.rows = response.data.items.data;
          this.page = response.data.items.current_page;
          this.pagination = {
            page: response.data.items.current_page,
            totalPages: response.data.items.last_page,
            per_page: response.data.items.per_page,
            totalRows: response.data.items.total,
          };
        })
      }
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getData()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
    edit(row) {
      this.$router.push(`/deposits/deposit/${row.id}`)
    },
    printDeposit(row) {
      this.printLoading = true;
      this.$api.GET_METHOD(`deposit/${row.id}`).then((response) => {
        this.printLoading = false;
        if (response.check) {
          response.data.data.id = row.id;
          response.data.data.branch_name = row.branch_name;
          this.printDepositMaster = response.data;
          this.printDepositNow = true;
          setTimeout(() => {
            this.printDepositMaster = {};
            this.printDepositNow = false;
          }, 1000);
        }
      });
    },
  },
};
</script>
