<template>
  <!-- <v-row justify="center"> -->
  <v-dialog v-model="dialog" persistent max-width="450">
    <v-card relative>
      <v-btn
        absolute
        class="ma-2"
        :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'"
        small
        fab
        color="grey lighten-1"
        text
        @click="close"
      >
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-col cols="10" class="ma-auto">
        <v-stepper v-model="activeStep" :key="key" elevation="0">
          <v-stepper-header>
            <v-stepper-step step="1">{{ $t("weight") }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2">{{ $t("pieces") }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3">{{ $t("price") }}</v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>

      <div class="pa-5 pt-0">
        <v-col cols="12" class="ma-auto" sm="11" v-if="activeStep == 1">
          <v-row justify="center">
            <v-col cols="11" sm="11" class="pa-0">
              <h5 class="mb-5">{{ $t("weight") }} :</h5>
              <NumberPad
                :backValueMethod="setWeight"
                :value="String(row.new_weight)"
              ></NumberPad>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="ma-auto" sm="11" v-if="activeStep == 2">
          <v-row justify="center">
            <v-col cols="12" class="py-0">
              <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                <div>
                  <h5>
                    {{ $t('weight') }} :
                    <br />
                    <span
                      class="text--disabled"
                    >{{ $global.DecimalFormatter(Number(row.new_weight).toFixed(2)) }} {{ $t('gram') }}</span>
                  </h5>
                </div>
                <v-btn small color="error" @click="activeStep = 1">
                  {{ $t('modify') }}
                  <img src="../../assets/img/svg/editIcon.svg" alt="qarat" />
                </v-btn>
              </div>
            </v-col>
            <v-col cols="11" sm="11" class="pa-0">
              <h5 class="my-2">{{ $t("Return Pieces") }} :</h5>
              <NumberPad
                :backValueMethod="setCount"
                :disabledDot="true"
                :value="String(row.new_pieces)"
              ></NumberPad>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="ma-auto" sm="11" v-if="activeStep == 3">
          <v-row justify="center">
            <v-col cols="6" class="py-0">
              <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                <div>
                  <h5>
                    {{ $t('weight') }} :
                    <br />
                    <span
                      class="text--disabled"
                    >{{ $global.DecimalFormatter(Number(row.new_weight).toFixed(2)) }} {{ $t('gram') }}</span>
                  </h5>
                </div>
                <v-btn small color="error" @click="activeStep = 1">
                  {{ $t('modify') }}
                  <img src="../../assets/img/svg/editIcon.svg" alt="qarat" />
                </v-btn>
              </div>
            </v-col>
            <v-col cols="6" class="py-0">
              <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
                <div>
                  <h5>
                    {{ $t('pieces') }} :
                    <br />
                    <span
                      class="text--disabled"
                    >{{ $global.DecimalFormatter(Number(row.new_pieces).toFixed(2)) }} {{ $t('gram') }}</span>
                  </h5>
                </div>
                <v-btn small color="error" @click="activeStep = 1">
                  {{ $t('modify') }}
                  <img src="../../assets/img/svg/editIcon.svg" alt="qarat" />
                </v-btn>
              </div>
            </v-col>
            <v-col cols="11" sm="11" class="mt-2 pa-0">
              <h5 class="mb-5">{{ $t("please enter price") }} :</h5>
              <NumberPad
                :backValueMethod="setPrice"
                :value="String(row.new_price)"
              ></NumberPad>
            </v-col>
          </v-row>
        </v-col>

        <v-row justify="space-around" class="my-1">
          <v-col cols="10" class="py-0 ma-auto" v-if="activeStep == 1">
            <v-btn
              color="success"
              large
              @click="activeStep = 2"
              :disabled="!(validWeight)"
              class="shadow"
              style="width: 100%"
            >{{ $t("next") }}</v-btn>
          </v-col>
          <v-col cols="10" class="py-0 ma-auto" v-if="activeStep == 2">
            <v-btn
              color="success"
              large
              @click="activeStep = 3"
              :disabled="!(validPieces)"
              class="shadow"
              style="width: 100%"
            >{{ $t("next") }}</v-btn>
          </v-col>
          <v-col cols="10" class="py-0" v-if="activeStep == 3">
            <v-btn
              color="success"
              :disabled="!(validPrice)"
              large
              block
              @click="add()"
              class="shadow"
            >{{ $t("add") }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
  <!-- </v-row> -->
</template>



<style scoped lang="scss">
</style>



<script>
import NumberPad from "../NumberPad.vue";

export default {
  name: "ReturnNoticeModal",
  props: {
    backValueMethod: { type: Function },
    row: { default: {} },
    setRow: { type: Function },
    loading: { default: false },
    dialog: { default: false },
    noticeType: { default: "creditor" }
  },
  computed: {
    key() {
      return this.row.m_id
    },
    validPrice() {
      if (this.noticeType == 'debtor') {
        return true;
      }
      else if (this.noticeType == 'creditor' && Number(this.row.new_price) <= Number(this.row.current_grand_total)) {
        return true;
      }
      else {
        return false;
      }
    },
    validWeight() {
      if (this.noticeType == 'debtor') {
        return true;
      }
      else if (this.noticeType == 'creditor' && Number(this.row.new_weight) <= Number(this.row.current_weight)) {
        return true;
      }
      else {
        return false;
      }
    },
    validPieces() {
      if (this.noticeType == 'debtor') {
        return true;
      }
      else if (this.noticeType == 'creditor' && Number(this.row.new_pieces) <= Number(this.row.current_pieces)) {
        return true;
      }
      else {
        return false;
      }
    },
  },
  mounted() {

  },
  components: {
    NumberPad,
  },
  methods: {
    setWeight(returnedWeight) {
      this.row.new_weight = Number(returnedWeight);
    },
    setCount(returnedCount) {
      this.row.new_pieces = Number(returnedCount);
    },
    setPrice(returnedPrice) {
      this.row.new_price = Number(returnedPrice);
    },
    add() {
      this.activeStep = 1
      this.backValueMethod(true);
      this.setRow({
        "d_id": this.row.d_id,
        "new_price": this.row.new_price,
        "new_weight": this.row.new_weight,
        "new_pieces": this.row.new_pieces,
      })
    },
    close() {
      this.setWeight(0);
      this.setCount(0);
      this.setPrice(0);
      this.activeStep = 1;
      this.backValueMethod(true);
    },
  },
  data() {
    return {
      weight: "",
      count: "",
      tab: null,
      activeStep: 1
    };
  },
};
</script>
