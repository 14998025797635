<template>
  <v-dialog v-model="dialog" persistent content-class="overflow-hidden" max-width="950">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="bg-white" :disabled="customer_id == null || customer_type != 1" :height="height || 'auto'"
        :block="block" v-bind="attrs" v-on="on" depressed rounded-md>
        <img class="mx-1" height="16" src="@/assets/img/svg/withdrawals.svg" alt="Qarat-POS" />
        <span class="d-md-block d-none" v-if="!selected_deposit.id">{{ $t('select deposit') }}</span>
        <span class="d-md-block d-none" v-else>{{ $t('change deposit') }}</span>
      </v-btn>
    </template>
    <v-card relative class="overflow-hidden pa-3 shadow-none">
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab color="grey lighten-1"
        text @click="dialog = false">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-h6">{{ $t('select from deposits') }}</v-card-title>
      <v-divider></v-divider>
      <v-row align="center" justify="end">
        <v-col cols="12" class="px-0">
          <v-card class="overflow-hidden pa-0 shadow-none" :max-height="this.$store.state.Settings.windowSize.y - 350">
            <DynamicTable :isLoading="isLoading" classes="shadow-none" :searchByDateRange="false" :showHeader="false"
              :viewTitle="false" :data="tableRows" :header="tableHeader" printURL="export_invoice_sales_qty">
              <template v-slot:td="{ row, header }">
                <v-btn :disabled="(row.receipt_voucher_id == selected_deposit.deposit_id)" color="success" v-if="header.key = 'select_deposit'"
                  width="100" depressed class="" @click="chooseDeposit(row)" small>
                  {{ $t('choose') }} 
                  <v-icon right size="14">mdi-check</v-icon>
                </v-btn>
              </template>
            </DynamicTable>
          </v-card>
        </v-col>
        <v-col cols="auto" class="pa-5 pt-2">
          <v-btn color="error" outlined @click="dialog = false">
            {{ $t('close') }}
          </v-btn>
        </v-col>
      </v-row>

    </v-card>
  </v-dialog>
</template>



<style scoped lang="scss"></style>



<script>
import DynamicTable from '../DynamicTable.vue';

export default {
  name: "CustomerDepositModal",
  props: {
    setDeposit: { default: Function },
    customer_id: { default: 0 },
    customer_type: { default: 1 },
    selected_deposit: { default: Object },
    block: { default: false },
    height: { default: 38 },
    total_price: { default: 0 },
  },
  computed: {
    tableHeader() {
      return [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "amount", key: "amount", type: 'float', classes: "" },
        { text: "description", key: "description", type: 'text', classes: "" },
        { text: "treasury", key: "treasury_name", type: 'text', classes: "" },
        { text: "machine", key: "machine_name", type: 'text', classes: "" },
        { text: "bank", key: "bank_name", type: 'text', classes: "" },
        { text: "action date", key: "action_date", type: 'text', classes: "" },
        { text: "select", key: "select_deposit", type: 'slot', classes: "", width: 80 },
      ]
    }
  },
  watch: {
    dialog() {
      if (this.dialog) {
        // if (this.customer_deposit_id !== this.customer_id) {
          this.getData();
        // }
      }
    }
  },
  mounted() {
  },
  data() {
    return {
      dialog: false,
      customer_deposit_id: null,
      isLoading: false,
      deposits: [],
      tableRows: [],
    };
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`get_customer_deposits/${this.customer_id}?total_invoice_amount=${this.total_price}`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.customer_deposit_id = this.customer_id
          this.tableRows = response.data.data;
          this.print_templates = response.data.prints;
        }
      })
    },
    chooseDeposit(row) {
      this.setDeposit(row);
      this.dialog = false;
    }
  },
  components: { DynamicTable }
};
</script>
