<template>
  <v-dialog content-class="overflow-hidden" v-model="withdrawalsDialog.dialog" persistent max-width="950">
    <v-card relative class="overflow-hidden">
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab color="grey lighten-1"
        text @click="closeDialog">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-h6">
        {{ withdrawalsDialog.supplier_id ? $t('supplier withdrawals') : $t('customer withdrawals') }}
      </v-card-title>
      <v-divider></v-divider>


      <v-row align="center" justify="end">
        <v-col cols="12" class="px-0">
          <v-card class="overflow-hidden pa-0 shadow-none" :max-height="this.$store.state.Settings.windowSize.y - 350">
            <DynamicTable :isLoading="isLoading" classes="shadow-none" :searchByDateRange="false" :showHeader="false"
              :viewTitle="false" :data="tableRows" :header="tableHeader" printURL="export_invoice_sales_qty">
              <template v-slot:td="{ row, header }">
                <div v-if="header.key == 'print'">
                  <v-btn @click="printMethod(row)" x-small icon fab>
                    <img src="@/assets/img/svg/pdf.svg" alt="Qarat-POS" />
                  </v-btn>
                </div>
              </template>
            </DynamicTable>
          </v-card>
        </v-col>
        <v-col cols="auto" class="pa-5 pt-2">
          <v-btn color="error"  outlined @click="closeDialog">
            {{ $t('close') }}
          </v-btn>
        </v-col>
      </v-row>

      <PrintTemplatesModal :dialog="printDialog" :print_templates="print_templates" :backValueMethod="closePrint"
        :invoice_id="invoice_id" />


    </v-card>
  </v-dialog>
</template>






<script>
import DynamicTable from '../DynamicTable.vue';
import PrintTemplatesModal from './PrintTemplatesModal.vue';

export default {
  name: "CustomerWithdrawals",
  props: {
    closeDialog: { type: Function },
    withdrawalsDialog: {
      default: {
        dialog: false,
        customer_id: null,
        supplier_id: null,
      }
    },
  },
  data: () => ({
    isLoading: false,
    tableRows: [],
    printDialog: false,
    print_templates: [],
    invoice_id: null,
  }),
  computed: {
    tableHeader() {
      return [
        { text: "invoice number", key: "m_id", type: 'text', classes: "" },
        { text: "customerName", key: "customer_name", type: 'text', classes: "" },
        { text: "customerMobile", key: "customer_mobile", type: 'text', classes: "" },
        { text: "payment type", key: "payment_type_title", type: 'text', classes: "" },
        { text: "vat", key: "grand_total_vat", type: 'price', classes: "" },
        { text: "finalCash", key: "final_total", type: 'price', classes: "" },
        { text: "date", key: "invoice_date", type: 'date', classes: "" },
        { text: "print", key: "print", type: 'slot', classes: "" },
      ]
    }
  },
  watch: {
    'withdrawalsDialog.dialog'() {
      if (this.withdrawalsDialog.dialog) {
        this.getData();
      }
    }
  },
  mounted() {

  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`get_sales_invoices_by_qty?customer_id=${this.withdrawalsDialog.customer_id || ''}&supplier_id=${this.withdrawalsDialog.supplier_id || ''}`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          this.tableRows = response.data.data;
          this.print_templates = response.data.prints;
        }
      })
    },
    printMethod(row) {
      if (this.print_templates.length > 1) {
        this.invoice_id = row.m_id
        this.printDialog = true;
      } else {
        const src = `${this.$api.serverUrl}export_invoice_sales_qty/${row.m_id}?type=print&clientID=${this.$store.state.clientID}`;
        this.$api.PrintInvoiceURL(src, row.m_id)
      }
    },
    closePrint() {
      this.printDialog = false;
      this.invoice_id = null
    },
  },
  components: { DynamicTable, PrintTemplatesModal }
};
</script>

<style scoped lang="scss"></style>