<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <div class="subtitle-2 text--disabled">
            <strong>{{ $t('usedInvoice') }} . {{ $t("invoiceList") }}</strong>
          </div>
        </v-col>
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <!-- <div class="subtitle-2">
            {{ $t('This data is shown starting from') }}
            <span class="text--disabled">
              {{
                  new Date(date).toLocaleDateString(
                    `${$vuetify.rtl == true ? "ar" : "en-UK"}`,
                    {
                      weekday: "short",
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    }
                  )
              }}
            </span>
          </div> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod"
            :controlRoute="'/purchases/create-purchases-invoice'" createBtnLabel="createaPurchaseInvoice"
            :printURL="'export_invoice_used_purchase'" :data="rows" :header="header">
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'id'">
                <v-btn @click="() => { purchaseInvoiceDialog = true; invoice_id = row.id }"
                  class="text-decoration-underline body-2 font-weight-bold info--text ma-1" small text>
                  {{ row.id }}
                </v-btn>
              </div>
            </template>
          </DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" class="py-0" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col>
      </v-row>
    </v-container>

    <PurchaseInvoiceModal :dialog="purchaseInvoiceDialog" :backValueMethod="closeDialog"
    :invoice_id="invoice_id" />
  </section>
</template>



<script>
import DynamicTable from "../../components/DynamicTable";
import Pagination from "../../components/Pagination";
import PurchaseInvoiceModal from "@/components/modals/PurchaseInvoiceModal.vue";
export default {
  name: "PurchasesTable",

  data: () => ({
    isLoading: true,
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 25,
    searchText: '',
    from: null,
    to: null,
    date: null,
    purchaseInvoiceDialog: false,
    invoice_id: 0,
  }),
  components: {
    DynamicTable,
    Pagination,
    PurchaseInvoiceModal,
  },
  computed: {},
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "invoice number", key: "id", type: 'slot', classes: "" },
        { text: "customerName", key: "customer_name", type: 'text', classes: "" },
        { text: "customerMobile", key: "customer_mobile", type: 'text', classes: "" },
        { text: "idNumber", key: "customer_id_number", type: 'text', classes: "" },
        { text: "caliber", key: "category_id", type: 'text', classes: "" },
        { text: "weight", key: "raw_weight", type: 'weight', classes: "" },
        { text: "finalCash", key: "total_price", type: 'price', classes: "" },
        { text: "date", key: "action_date", type: 'date', classes: "" },
        { text: "branch", key: "branch_name", type: 'text', classes: "" },
        { text: "added by", key: "user_full_name", type: 'text', classes: "" },
        { text: "print", key: "id", type: 'print', classes: "" },
      ]
    },
    getData() {
      this.isLoading = true;
      if (this.$global.CheckAction("06-100", 1)) {
        this.$api.GET_METHOD(`get_used_invoices?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText}&date=${this.date || ''}`).then((response) => {
          this.isLoading = false;
          this.rows = response.data.data.data;
          this.page = response.data.data.current_page;
          this.pagination = {
            page: response.data.data.current_page,
            totalPages: response.data.data.last_page,
            per_page: response.data.data.per_page,
            totalRows: response.data.data.total,
          };
        })
      }
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getData()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
    closeDialog(){
      this.purchaseInvoiceDialog = false;
    }
  },
};
</script>
