<template>
  <section>
    <v-container fluid v-if="isLoading">
      <SkeletonInvoiceLoader></SkeletonInvoiceLoader>
    </v-container>
    <v-container fluid v-if="!isLoading">
      <v-row>
        <v-col class="px-0" cols="12" md="6">
          <!-- <v-col cols="6" lg="2" md="4">
            <v-btn
              class="light rounded-lg cursor_pointer caption text-center"
              @click="usedModal = true"
              depressed
              block
              height="40"
              :class="'font-weight-bold'"
            >{{ $t('used') }}</v-btn>
          </v-col>-->
          <SalesForm :gold_price="gold_price" :vats="vats"></SalesForm>
        </v-col>
        <v-col cols="12" md="6">
          <v-row justify="space-between" align="center">
            <v-col class="mb-lg-5 mt-2 mx-lg-2 pa-0 pa-sm-2" cols="auto">
              <p class="ma-0" v-if="customerType == 1">
                {{ $t("cash customer") }} :
                <span
                  v-if="!customer"
                  class="text--disabled"
                >{{ $t('no customer selected') }}</span>
                <span v-if="customer">{{ customer.name }}</span>
              </p>
              <p class="ma-0" v-if="customerType == 2">
                {{ $t("late customer") }} :
                <span
                  v-if="!customer"
                  class="text--disabled"
                >{{ $t('no customer selected') }}</span>
                <span v-if="customer">{{ customer.name }}</span>
              </p>
            </v-col>
            <v-col class="mb-5 mt-2 mx-2" cols="auto" :key="`customer${invoiceKey}`">
              <div class="d-flex align-center">
                <!-- <v-btn-toggle :value="customerType" tile class="d-block mx-3" group>
                  <v-btn
                    :value="1"
                    v-on:click="changeCustomerType(1)"
                    class="mx-2 rounded"
                    elevation="0"
                    :height="38"
                  >
                    <v-icon color="primary" :disabled="customerType != 1" left>mdi-check-circle</v-icon>
                    {{ translation['cash customer'] }}
                  </v-btn>
                  <v-btn
                    :value="2"
                    v-on:click="changeCustomerType(2); customer = customer.id == 1 ? null : customer"
                    class="mx-2 rounded"
                    elevation="0"
                    :height="38"
                  >
                    <v-icon color="primary" :disabled="customerType != 2" left>mdi-check-circle</v-icon>
                    {{ translation['late customer'] }}
                  </v-btn>
                </v-btn-toggle>-->
                <CustomerModalVue :backValueMethod="setCustomer"></CustomerModalVue>
              </div>
            </v-col>
          </v-row>
          <div class="mt-2">
            <InvoiceData></InvoiceData>
          </div>
          <v-col cols="12" class="px-0 mt-2">
            <v-card class="shadow rounded-lg justify-center pa-5">
              <v-form ref="form" v-model="form" lazy-validation>
                <v-row>
                  <v-col cols="auto" class="px-4">
                    <PaymentMethod
                      :type="null"
                      :backValueMethod="setPayment"
                      :machineID="machineId"
                    ></PaymentMethod>
                  </v-col>
                  <v-col cols="auto" class="px-4">
                    <BankModal
                      :machines="machines"
                      :machine="machineId"
                      :backValueMethod="setMachine"
                    ></BankModal>
                  </v-col>
                  <v-col cols="auto" class="px-4">
                    <SellarModal :checkChangeAgent="false"
                      :sales_agents="sales_agents"
                      :sellar="salesAgent"
                      :backValueMethod="setAgent"
                    ></SellarModal>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-3 mt-2">
            <v-row justify="space-between">
              <v-col cols="auto">
                <h5>{{ translation.finalCash }}</h5>
                <h5>
                  <strong>{{ $global.DecimalFormatter(Number(totalPrice.toFixed(2)).toFixed(2)) }}</strong>
                  <!-- <small>{{ translation.ryal }}</small> -->
                </h5>
              </v-col>
              <v-col cols="auto">
                <h5 class="text--disabled">{{ translation.vat }}</h5>
                <h5>
                  <strong>{{ $global.DecimalFormatter(Number(totalVat.toFixed(2)).toFixed(2)) }}</strong>

                  <!-- <small>{{ translation.ryal }}</small> -->
                </h5>
              </v-col>
              <v-col cols="auto">
                <h5 class="text--disabled">{{ translation.totalPaid }}</h5>
                <h5>
                  <strong>{{ $global.DecimalFormatter(Number(totalPaid.toFixed(2)).toFixed(2)) }}</strong>
                </h5>
              </v-col>
              <v-col cols="auto">
                <h5
                  class="text--disabled"
                  v-if="!(Math.round(totalPaid) >= Math.round(totalPrice))"
                >{{ translation.remining }}</h5>
                <h5
                  class="text--disabled"
                  v-if="(Math.round(totalPaid) >= Math.round(totalPrice))"
                >{{ translation.more }}</h5>
                <h5
                  :class="!(totalPaid.toFixed(2) >= totalPrice.toFixed(2)) ? 'error--text' : 'success--text'"
                >
                  <strong>{{ $global.DecimalFormatter(Number(totalPrice.toFixed(2) - totalPaid.toFixed(2)).toFixed(2)) }}</strong>
                </h5>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-3 mt-2">
            <v-row justify="end">
              <v-col cols="auto">
                <v-btn
                  class="shadow"
                  @click="resetPayment"
                  large
                  color="error"
                  outlined
                >{{ translation.deletePaid }}</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  class="shadow"
                  :disabled="!valid"
                  @click="saveInvoice"
                  :loading="loading"
                  large
                  color="success"
                >{{ $t('save') }}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
      <UsedModal
        :vats="vats"
        :dialog="usedModal"
        :gold_price="gold_price"
        :backValueMethod="usedModalMethod"
      ></UsedModal>
    </v-container>
  </section>
</template>



<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
import SellarModal from "../../components/modals/SellarModal.vue";
import PaymentMethod from "../../components/modals/PaymentMethod.vue";
import BankModal from "../../components/modals/BankModal.vue";
import InvoiceData from "../../components/InvoiceData.vue";
import SalesForm from "../../components/SalesForm.vue";
import router from "../../router";
import SkeletonInvoiceLoader from "../../components/SkeletonInvoiceLoader.vue";
import CustomerModalVue from "../../components/modals/CustomerModal.vue";
import UsedModal from "../../components/modals/UsedModal.vue";
export default {
  name: "SalesInvoicesPiece",
  computed: {
    ...mapState(["bill", "totalPrice", "priceWithoutVat", "totalVat", "customerType", "goldType", "isTypesLoading"]),
    translation() {
      return this.$store.state.Settings.translation;
    },
    valid() {
      if (this.customerType == 1) {
        if (this.salesAgent && this.customer !== "" && Math.round(this.totalPrice) <= Math.round(this.totalPaid) && this.bill.length !== 0) {
          return true
        } else {
          return false
        }
      }
      else {
        if (this.salesAgent && this.customer !== "" && this.bill.length !== 0) {
          return true
        } else {
          return false
        }
      }
    }
  },
  data: () => ({
    invoiceKey: 1,
    form: false,
    createBill: false,
    goldCaliber: 18,
    toggle_caliber: 1,
    salesForm: false,
    isLoading: true,
    subCategories: [],
    categoryID: "",
    gold_price: {},
    vats: {},
    sales_agents: [],
    machines: [],
    customer: {
      "id": 1,
      "name": "عميل نقدي",
    },
    customerID: "",
    salesAgent: "",
    machineId: "",
    payments: [],
    totalPaid: 0,
    printID: 0,
    printContent: {},
    printLoading: false,
    usedModal: false
  }),
  components: {
    InvoiceData,
    SalesForm,
    SellarModal,
    PaymentMethod,
    BankModal,
    SkeletonInvoiceLoader,
    CustomerModalVue,
    UsedModal,
  },
  watch: {
    customerType() {
      if (this.customerType == 2) {
        this.customer = null
      }
    }
  },
  mounted() {
    this.changeCustomerType(1);
    this.$api.GET_METHOD("get_create_invoice_data_by_piece").then((response) => {
      this.isLoading = false;
      this.gold_price = response.data.gold_price
      this.subCategories = response.data.sub_categorys
      this.vats = response.data.vats
      this.sales_agents = response.data.sales_agents
      this.machines = response.data.machines
      if (response.data.sales_agents.length > 0) {
        this.salesAgent = response.data.sales_agents[0]
      }
      if (response.data.machines.length > 0) {
        this.machineId = response.data.machines[0]
      }
    })
    if (!this.$store.state.permissions.includes("06-001")) {
      router.push('/login')
    }

  },
  destroyed: function () {
    this.clearBill().finally(() => { });
  },
  methods: {
    ...mapActions(["addBillAction", "clearBill", "changeCustomerType"]),

    setAgent(returnedValue) {
      this.salesAgent = returnedValue;
    },
    resetPayment() {
      this.totalPaid = 0;
      this.payments = [];
      this.invoiceKey = this.invoiceKey + 1
    },
    setMachine(returnedValue) {
      this.machineId = returnedValue;
    },
    setCustomer(returnedValue) {
      this.customer = returnedValue;
    },
    usedModalMethod() {
      this.usedModal = false
    },
    setPayment(returnedValue) {
      this.payments = returnedValue;
      this.totalPaid = this.payments.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.amount);
      }, 0);
    },
    saveInvoice() {
      this.$refs.form.validate();
      let requestBody = {
        "salesAgent": this.salesAgent.id,
        "machineId": Number(+this.machineId.id),
        "customerID": this.customer.id,
        "customerType": this.customerType,
        "items": this.bill,
        "payments": this.payments,
      }
      if (this.bill.length == 0) {
        this.$swal.fire({
          title: this.translation["Please Check"],
          text: this.translation["noItemsAdded"],
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // console.log("requestBody", requestBody);
      this.loading = true;
      this.$api.POST_METHOD("create_invoice_by_piece", requestBody, true, this.translation["Invoice Created Successfully"]).then((response) => {
        this.loading = false;
        if (response.check) {
          this.customer = {
            "id": 1,
            "name": "عميل نقدي",
          },
            this.changeCustomerType(1);
          this.totalPaid = 0;
          this.resetPayment();
          this.clearBill();
          this.printLoading = true;
          this.customerID = ""
          this.printID = response.data
          // this.$api.DOWNLOAD_METHOD(`export_invoice_sales_piece/${response.data}?type=pdf`)
          const src = `src="${this.$api.serverUrl}export_invoice_sales_piece/${response.data}?type=image&clientID=${this.$store.state.clientID}"`;
          this.$api.PrintInvoice(src,response.data);
        }
      })

    },
  },
};
</script>

<style  lang="scss">
</style>