<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" class="d-flex justify-center align-center py-0">
          <v-row align="center" justify="space-between">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center me-3 text--disabled">
                <strong>{{ $t('deposits') | capitalize }} #{{ $route.params.id }} </strong>
              </div>
            </v-col>
            <v-col cols="12" md="auto">
              <v-btn color="transparent" class="error--text mx-2" :width="120" depressed @click="$router.go(-1)">
                <v-icon size="14" class="mt-1 mx-2">mdi-arrow-right</v-icon>
                {{ $t('back') }}
              </v-btn>
              <v-btn v-if="$global.CheckAction('06-023', 5)" color="white" class="gray2--text shadow" :width="120" :loading="printLoading" depressed
                @click="printInvoice">
                {{ $t('print') }}
                <img class="mx-2" height="20" src="@/assets/img/svg/pdf.svg" alt="Qarat-POS" />
              </v-btn>
              <!-- <v-btn color="red11" class="error--text mx-2" :loading="isDeleteLoading" :width="120" depressed
                @click="deleteDialog = true">
                <v-icon size="14" left>mdi-delete</v-icon>
                {{ $t('delete') }}
              </v-btn>
              <v-btn color="primary" :width="120" depressed @click="edit">
                {{ $t('edit') }}
              </v-btn> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="pa-0">
          <ControlSummery :summery="summery" :loading="isLoading" />
        </v-col>

      </v-row>


      <DeleteConfirmation :dialog="deleteDialog" :item="null" :backValueMethod="deleteMethod" />
      <ExitConfirmationModal :dialog="exitDialog" :closeDialog="() => exitDialog = false" />
      <DepositsPrint :print="printDepositNow" :printAction="() => { printDepositNow = false; }"
        :printStaticData="printDepositMaster" :printData="[]" />
    </v-container>
  </section>
</template>



<script>

import ControlSummery from "@/components/ui/ControlSummery.vue";
import DeleteConfirmation from "@/components/modals/DeleteConfirmation.vue";
import ExitConfirmationModal from "@/components/modals/ExitConfirmationModal.vue";
import DepositsPrint from "./DepositsPrint.vue";
export default {
  name: "DepositsView",

  data: () => ({
    isLoading: false,
    exitDialog: false,
    isDeleteLoading: false,
    deleteDialog: false,
    master: Object,
    details: [],
    summery: [],
    responseData: Object,
    type: 'cash',
    printLoading: false,
    printDepositNow: false,
    printDepositMaster: {},
  }),
  components: {
    ControlSummery,
    DeleteConfirmation,
    ExitConfirmationModal,
    DepositsPrint
  },
  watch: {

  },
  computed: {

  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {

    },
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`deposit/${this.$route.params.id}`).then((response) => {
        this.isLoading = false;
        response.data.data.id = +this.$route.params.id;
        this.printDepositMaster = response.data;
        this.responseData = response.data;
        this.master = response.data.data || Object;
        this.details = [];
        this.summery = [
          {
            label: 'date',
            value: response.data.data.action_date,
            type: response.data.data.action_date ? 'date' : 'text',
            class: '',
            cols: null, sm: null, md: null, lg: 4, xl: 4,
          },
          {
            label: 'added by',
            value: response.data.data.user_full_name,
            class: '',
            cols: null, sm: null, md: null, lg: 4, xl: 4,
          },

          {
            label: 'amount',
            value: response.data.data.amount,
            type: 'float',
            class: '',
            cols: null, sm: null, md: null, lg: 4, xl: 4,
          },
          {
            label: 'pay for',
            value: response.data.data.pay_for,
            class: '',
            cols: null, sm: null, md: null, lg: 4, xl: 4,
          },
          {
            label: 'deposit code',
            value: response.data.data.deposit_code_id ? response.data.deposits_codes.find(code => code.id === response.data.data.deposit_code_id).name_ar : '-',
            class: '',
            cols: null, sm: null, md: null, lg: 4, xl: 4,
          },
          {
            label: 'tax',
            value: response.data.data.tax_id ? response.data.taxes.find(tax => tax.id === response.data.data.tax_id).name : '-',
            class: '',
            cols: null, sm: null, md: null, lg: 4, xl: 4,
          },
          {
            label: 'branch',
            value: response.data.data.branch_name,
            class: '',
            cols: null, sm: null, md: null, lg: 4, xl: 4,
          },
          {
            label: 'description',
            value: response.data.data.description,
            type: 'text',
            class: '',
            cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
          },

        ];
      })
    },
    edit() {
      this.$router.push({
        name: "DepositsView",
        params: { master: this.master, responseData: this.responseData },
      });
    },
    printInvoice() {
      this.printLoading = true;
      this.printDepositNow = true;
      setTimeout(() => {
        this.printLoading = false;
        this.printDepositNow = false;
      }, 10);
    },

    deleteMethod(status) {
      if (status == true) {
        this.isDeleteLoading = true;
        this.deleteDialog = false
        this.$api.POST_METHOD(`${this.type == 'cash' ? 'money_transfer' : 'metal_transfer'}/${this.master.id}`, { _method: 'delete' }).then((response) => {
          this.isDeleteLoading = false
          if (response.check) {
            this.$router.push('/transfers');
          }
        })
      } else {
        this.deleteDialog = false
      }
    }
  },
};
</script>
