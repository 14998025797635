<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" class="d-flex justify-center align-center py-0 mb-6">
          <v-row align="center" justify="space-between">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center me-3 text--disabled">
                <strong>{{ $t('expenses') | capitalize }} . {{ $t('add') | capitalize }}</strong>
              </div>
            </v-col>
            <v-col cols="12" md="auto">
              <v-btn color="red12" class="error--text mx-2" :width="120" depressed @click="exitDialog = true">
                {{ $t('cancel') }}
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn color="success" :min-width="120" :disabled="!(valid && +this.form.amount)" :loading="saveIsLoading" depressed @click="save">
                {{ $t('create invoice') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="shadow">

        <v-row justify="center" v-if="isLoading">
          <v-col cols="auto">
            <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
              indeterminate></v-progress-circular>
            <p class="text-center">{{ $t('Loading data') }}</p>
          </v-col>
        </v-row>

        <v-form ref="form" v-model="valid" class="pa-5" v-if="!isLoading">
          <v-sheet class="backgroundW py-0 pa-5">
            <v-row justify="space-between" align="center" class="justify-xl-space-around py-4 py-xl-6">

              <v-col cols="12" md="4" lg="4" xl="3">
                <v-sheet max-width="400" class="d-block mt-n3 ma-auto">
                  <label>{{ $t('amount') }}*</label>
                  <NumberPad :backValueMethod="setAmount" :value="String(form.amount)" />
                </v-sheet>
              </v-col>

              <v-col cols="auto" class="d-none py-16 d-md-block">
                <v-divider vertical style="height: 220px ;"></v-divider>
              </v-col>
              <v-col cols="12" md="7" lg="7" xl="8">
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <v-col cols="12">
                      <GenericInput type="date" :solo="false" :dense="true" classes="rounded-lg" :value="form.action_date"
                        @input="form.action_date = $event" label="date" :required="true" :hide-details="true"
                        :cols="[12, 12, 12]">
                      </GenericInput>
                    </v-col>

                    <v-col cols="12">
                      <GenericInput type="text" :solo="false" :dense="true" classes="rounded-lg" :value="form.pay_for"
                        @input="form.pay_for = $event" label="pay for" :required="true" :hide-details="true"
                        :cols="[12, 12, 12]">
                      </GenericInput>
                    </v-col>

                    <v-col cols="12">
                      <GenericInput type="select" :lookups="expenses_codes" :value="form.expense_code_id"
                        @input="form.expense_code_id = $event" label="choose an expenses code" :multi="false"
                        :required="true" selected_label="name_ar" selected_prop="id" :hide-details="true"
                        :isLoading="false" :cols="[12, 12, 12]">
                      </GenericInput>
                    </v-col>

                    <v-col cols="12">
                      <GenericInput type="select" :lookups="taxes" :value="form.tax_id" @input="form.tax_id = $event"
                        label="tax" :multi="false" :required="false" selected_label="name" selected_prop="id"
                        :hide-details="true" :isLoading="false" :cols="[12, 12, 12]">
                      </GenericInput>
                    </v-col>
                  </v-col>
                  <v-col cols="12" md="6">
                    <GenericInput type="textarea" :solo="false" :dense="true" :rowsNumber="9" classes="rounded-lg"
                      :value="form.description" @input="form.description = $event" label="description" :required="true"
                      :hide-details="true" :cols="[12, 12, 12]">
                    </GenericInput>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-sheet>
        </v-form>

      </v-card>
      <ExitConfirmationModal :dialog="exitDialog" :closeDialog="() => exitDialog = false" exit_to="/expenses" />
    </v-container>
  </section>
</template>



<script>
import NumberPad from "@/components/NumberPad.vue";
import GenericInput from "@/components/GenericInput.vue";
import ExitConfirmationModal from "@/components/modals/ExitConfirmationModal.vue";
export default {
  name: "ExpensesCreate",
  computed: {

  },
  data: () => ({
    valid: false,
    isLoading: false,
    saveIsLoading: false,
    exitDialog: false,
    expenses_codes: [],
    taxes: [],
    key: 1,
    form: {
      expense_code_id: null,
      amount: 0,
      action_date: null,
      description: null,
      pay_for: null,
      tax_id: null,
    },
  }),
  components: {
    NumberPad,
    GenericInput,
    ExitConfirmationModal
},
  watch: {},
  mounted() {
    this.form.action_date = this.$global.GetCurrentDate();
    this.getData()
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$api
        .GET_METHOD(`expense/create`)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.expenses_codes = response.data.expenses_codes || [];
            this.taxes = response.data.taxes || [];
          }
        });
    },
    save() {
      this.saveIsLoading = true;
      this.$api.POST_METHOD(`expense`, this.form).then((response) => {
        this.saveIsLoading = false;
        if (response.check) {
          const src = `src="${this.$api.serverUrl}export_expense/${response.data.id}?type=image&clientID=${this.$store.state.clientID}"`;
          this.$api.PrintInvoice(src, response.data.id)
          this.form = {
            expense_code_id: null,
            amount: 0,
            action_date: this.$global.GetCurrentDate(),
            description: null,
          };
        }
      })
    },
    setAmount(returnedAmount) {
      this.form.amount = +returnedAmount;
    },

  },
};
</script>

