<template>
   <v-container>
      <div>
         <v-btn color="error" class="closeBtn" to="/login"><v-icon left>mdi-close-circle</v-icon>{{ $t('close') }}</v-btn>
         <vue-html2pdf :show-layout="true" :float-layout="true" :enable-download="false" :preview-modal="true"
            :filename="report.user_full_name" :pdf-quality="2" :manual-pagination="true" pdf-format="a4"
            pdf-orientation="portrait" ref="html2Pdf">
            <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">

               <PrintPDF :header="[]" :maxPDFRows="3" :data="expenses.length ? expenses : [{}]" :header_slot="false"
                  :content_slot="true" :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
                  :customize_header_footer="true" :printStaticData="printStaticData">
                  <template v-slot:pdf_content="{ page, table_data, isLastPage }">

                     <v-col id="print_title" v-if="page == 1">
                        {{ `${$t('workday report')}` }}
                     </v-col>

                     <ShiftPrint2 :page="page" :isLastPage="isLastPage" :expenses="table_data" :is_view="false"
                        v-if="temp == 'temp_2'" :report="report" :printStaticData="printStaticData" />

                  </template>
               </PrintPDF>

            </section>
         </vue-html2pdf>
      </div>
   </v-container>
</template>

<script>
import PrintPDF from "@/components/PrintPDF.vue";
import VueHtml2pdf from 'vue-html2pdf'
import ShiftPrint2 from "./ShiftPrint2.vue";
export default {
   name: "ShiftSummery",
   computed: {

   },
   watch: {

   },
   mounted() {
      if (this.$route.params.report && this.$route.params.printStaticData) {
         this.report = this.$route.params.report;
         this.expenses = this.$route.params.report.ec_list || [];
         this.printStaticData = this.$route.params.printStaticData;
         this.$refs.html2Pdf.generatePdf();
      } else {
         this.$router.push('/login')
      }
   },
   components: {
      PrintPDF,
      VueHtml2pdf,
      ShiftPrint2
   },
   data() {
      return {
         report: Object,
         printStaticData: Object,
         expenses: [],
         temp: 'temp_2',
      }
   },
   methods: {
   },
};
</script>

<style lang="scss" scoped>
.closeBtn {
   position: absolute;
   left: 60px;
   top: 15px;
   z-index: 99999;
}

.summary_row {
   padding: 0.4rem 0.2rem;
   border-bottom: 2px solid #eee;
   font-size: 12px !important;
   display: flex !important;
   justify-content: space-between !important;
}

td {
   padding: 1.2rem 0.4rem;
   font-size: 12px !important;
}

td:nth-of-type(1),
td:nth-of-type(4) {
   color: var(--gray7) !important;
}

#print_title {
   width: fit-content;
   text-align: center;
   font-size: 12px !important;
   color: var(--blue1);
   background: var(--blue12);
   display: block;
   padding: 5px;
   font-weight: bold;
   margin: 20px auto;
}

.mini_table_two {
   width: 100%;
   border-collapse: collapse !important;

   tr {
      border: 1px solid #f7f9fb !important;

      th {
         text-transform: capitalize;

      }

      td,
      th {
         border: 1px solid #eee !important;
         background: #fff !important;
         height: 34.5px !important;
         font-size: 12px !important;
         padding: 12px;

      }

      td {
         background-color: #f5f6fa;
      }

      &:nth-of-type(odd) {

         td,
         th {
            background: #f7f9fb !important;
         }
      }

      // &:last-of-type {
      //
      //    td,
      //    th {
      //       font-weight: bold;
      //    }
      // }
   }
}</style>