<template>
  <v-dialog v-model="discountDialog" overlay-opacity="0.75" persistent :max-width="width" :key="dialogKey">

    <v-card relative class="backgroundW py-3">
      <v-btn absolute class="" :style="$vuetify.rtl == true ? 'left: 5px' : 'right: 5px'" small fab
        color="grey lighten-1" text @click="closeDialog">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <template v-if="discountAccess">
        <v-card-title class="subtitle-1 font-weight-bold">
          {{ $t('choose discount type') | capitalize }}
        </v-card-title>

        <v-col cols="10" class="d-block mt-2 ma-auto">
          <v-row align="center" justify="space-between">
            <v-col v-for="type in discountTypes" :key="type.id" cols="4" class="py-0">
              <v-btn depressed block :outlined="!(discount_type == type.id)" width="130px"
                :color="discount_type == type.id ? 'primary' : 'gray5'" @click="discount_type = type.id">
                <v-icon size="19" left>{{ type.icon }}</v-icon>
                {{ $t(type.name) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10" class="d-block ma-auto" v-if="!couponsTab">
          <NumberPad :backValueMethod="setValue" :value="String(discount_value || '')" />
        </v-col>
        <v-col cols="10" class="d-block my-5 mb-10 ma-auto" v-if="couponsTab">
          <GenericInput type="text" :dense="false" :outlined="true" backgroundColor="white" selected_label="coupon_code"
            :solo="false" paddingY="py-2 py-lg-0" paddingX="px-0" selected_prop="id" :value="coupon_code" :multi="false"
            @input="coupon_code = $event" label="coupon" :isLoading="requestLoading" :hideDetails="true"
            :cols="[12, 12, 12]" />
        </v-col>

        <v-col cols="10" class="d-block ma-auto pt-0">
          <v-row justify="space-between">
            <v-col cols="auto" class="pt-0">
              <v-btn :height="40" class="shadow-none" text plain @click="closeDialog" color="error" :loading="false"
                style="width: 100%; text-align: start">
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pt-0">
              <v-btn :height="40" v-if="discount_type !== 3" :disabled="!(saveValid)" class="shadow-none" @click="save"
                min-width="120" color="success" :loading="false" style="width: 100%; text-align: start">
                {{ $t('save') }}</v-btn>

              <v-btn :height="40" v-else :disabled="!(coupon_code)" class="shadow-none" @click="couponCodeSearch"
                min-width="120" color="secondary" :loading="requestLoading" style="width: 100%; text-align: start">
                {{ $t('search') }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </template>

      <template v-else>
        <v-card-title class="subtitle-1 font-weight-bold">
          {{ $t('please enter username and password first') | capitalize }}
        </v-card-title>
        <div class="pa-5 pb-3">
          <GenericInput type="text" :value="userForm.username" @input="userForm.username = $event" label="username"
            :required="true" :isLoading="false" :cols="[12, 12, 12]" :outlined="true" name="new-username" icon="mdi-account" />
          <GenericInput type="password" :value="userForm.password" @input="userForm.password = $event" label="password"
            :required="true" :isLoading="false" :cols="[12, 12, 12]" name="new-password" />
          <v-col class="d-flex justify-end">
            <v-btn :height="40" :disabled="!(userForm.username && userForm.password)" class="shadow-none"
              @click="checkPermission" :loading="requestLoading" min-width="120" color="success">
              {{ $t('next') }}</v-btn>
          </v-col>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>



<style scoped lang="scss"></style>



<script>
import NumberPad from '../NumberPad.vue';
import { createNamespacedHelpers } from "vuex";
import GenericInput from '../GenericInput.vue';
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "DiscountModal",
  props: {
    width: { default: 550 },
    dialog: { default: false },
    coupons: { default: [] },
    setDiscount: { default: Function },
    returnCloseDialog: { default: Function },
    customer_data: { default: {} },
    customerType: { default: 1 },
  },
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.key == 'Enter' && this.dialog && this.saveValid && this.discount_type !== 3) {
        this.save();
        this.dialogKey = this.dialogKey + 1
      }
      else if (e.key == 'Enter' && this.discount_type == 3 && this.coupon_code && this.customer_data.id) {
        this.couponCodeSearch();
      }
    });
  },
  computed: {
    ...mapState(["totalPriceWithVat", "totalPrice", "discountDialog", "discount"]),
    saveValid() {
      if (this.discount_type == 1 && Number(this.discount_value) >= this.totalPrice) {
        return false
      }
      if (this.discount_type == 2 && Number(this.discount_value) >= 100) {
        return false
      }
      if (this.discount_type == 3) {
        let coupon = this.coupons.find(coupon => coupon.id == this.coupon) || {}
        if (coupon.coupon_type == 1 && coupon.discount >= this.totalPrice) {
          return false
        } else if (coupon.coupon_type == 2 && coupon.discount >= 100) {
          return false
        } else {
          return true
        }
      }
      else {
        return true
      }
    },
    availCoupons() {
      return this.coupons
    },
    discountAccess() {
      return this.$store.state.userData?.discount == 1 || this.user_id
    },
    selectedCoupon() {
      return this.coupons.find(coupon => coupon.id == this.coupon) || {}
    },
  },
  data: () => ({
    discount_value: 0,
    coupon_code: null,
    user_id: null,
    dialogKey: 1,
    discount_type: 2,
    coupon: 0,
    couponsTab: false,
    requestLoading: false,
    discountTypes: [
      {
        id: 1,
        name: 'value',
        icon: 'mdi-currency-usd'
      },
      {
        id: 2,
        name: 'percentage',
        icon: 'mdi-percent-outline'
      },
      {
        id: 3,
        name: 'coupon',
        icon: 'mdi-ticket-percent-outline'
      },
    ],
    userForm: {
      username: null,
      password: null,
    }
  }),
  methods: {
    ...mapActions(["toggleDiscountDialog"]),
    closeDialog() {
      this.user_id = null;
      this.userForm = {
        username: null,
        password: null,
      }
      this.toggleDiscountDialog();
      // this.couponsTab = false;
    },
    setValue(value) {
      this.discount_value = Number(value);
    },
    save() {
      var discount = {
        type: this.discount_type,
        value: this.discount_value,
        is_coupon: 0,
        coupon_id: 0,
        discount_amount: 0,
        percent_value: 0,
        user_id: this.user_id || this.$store.state.userData.id,
        coupon: {}
      }
      this.setDiscount(discount);
      this.couponsTab = false;
    },
    couponCodeSearch() {
      if (this.customer_data.id && this.coupon_code) {
        this.requestLoading = true;
        this.$api.GET_METHOD(`check_coupon?coupon_code=${this.coupon_code}&customer_id=${this.customer_data.id}`).then((response) => {
          this.requestLoading = false;
          if (response.check) {
            var discount = {
              type: 3,
              value: +response.data.coupon.discount,
              coupon_id: +response.data.coupon.coupon_id,
              is_coupon: 1,
              discount_amount: 0,
              percent_value: 0,
              user_id: this.user_id || this.$store.state.userData.id,
              coupon: {
                id: response.data.coupon.coupon_id || 1,
                coupon_code: this.coupon_code,
                ...response.data.coupon
              }
            }
            this.setDiscount(discount);
            this.couponsTab = false;
            this.discount_type = 1
          }
        });
      }

    },
    checkPermission() {
      this.requestLoading = true;
      this.$api.POST_METHOD(`login`, this.userForm).then(({ check, data }) => {
        this.requestLoading = false;
        if (check) {
          if (data.discount) {
            this.user_id = data.id;
          } else {
            this.$global.ShowAlert('error', 'user not have permission to add discount')
          }
        }
      });
    },
  },

  watch: {
    'discount_type'() {
      this.discount.value = 0;
      if (this.discount_type == 3) {
        this.couponsTab = true;
      } else {
        this.couponsTab = false;
        this.coupon = null
      }
    },
    dialog() {
      this.coupon_code = null;
      this.discount_value = this.discount.value;
      this.discount_type = this.discount.type || 1
    }
  },
  components: {
    NumberPad,
    GenericInput
  }
};
</script>
