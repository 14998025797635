<template>
  <v-dialog v-model="dialog" persistent max-width="450">
    <v-card relative>
      <v-card-title class="text-h6 font-weight-bold">
        <img src="@/assets/img/svg/close.svg" height="30" class="mx-2" alt="erp" />
        <h5>
          <span>{{ $t('Will you be logged out of the system?') }}</span>
        </h5>
      </v-card-title>

      <v-col cols="12" sm="12">
        <div class="body-2 px-5">
          <p>
            {{ $t('When you log out, your working hours will expire and the operations you have performed throughout the working hours will be saved') }}
          </p>
        </div>
      </v-col>

      <v-col cols="12" class="py-4">
        <v-row justify="space-around">
          <v-col cols="5">
            <v-btn @click="closeDialog" depressed color="backgroundW" style="width: 100%; text-align: start">{{
              $t('Cancel') }}</v-btn>
          </v-col>
          <v-col cols="5">
            <v-btn color="secondary" @click="returnData" class="shadow" style="width: 100%; text-align: start">{{
              $t('logout') }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>



<style scoped lang="scss"></style>



<script>

export default {
  name: "LogoutConfirmation",
  props: {
    backValueMethod: { type: Function },
    dialog: { default: false },
  },
  computed: {},
  data() {
    return {
    };
  },
  methods: {
    closeDialog() {
      this.backValueMethod(false);
    },
    returnData() {
      this.backValueMethod(true);
    },

  },
  mounted() {
  }
};
</script>
