<template>
  <v-card elevation="0" class=" justify-center py-0">
    <!-- <v-col cols="6" sm="12" class="px-sm-8">
      <h4 >{{ translation[tableTitle] }}</h4>
    </v-col> -->
    <v-col cols="12" sm="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th width="33.33%" v-for="(header, index) in headers" :key="index">{{ $t(header.text) }}</th>
            </tr>
          </thead>
          <tbody v-if="tableTitle == 'salesInvoices'">
            <tr>
              <td><img src="../assets/img/svg/gold.svg" class="mx-1" alt="qarat">{{ $t('caliber') + " " + 18 }}</td>
              <td>{{ summary.sales.caliber18Weight || 0 | float }} {{ $t('gram') }}</td>
              <td>{{ summary.sales.caliber18Price || 0 | float }} {{ $t('ryal') }}</td>
            </tr>
            <tr>
              <td><img src="../assets/img/svg/gold.svg" class="mx-1" alt="qarat">{{ $t('caliber') + " " + 21 }}</td>
              <td>{{ summary.sales.caliber21Weight || 0 | float }} {{ $t('gram') }}</td>
              <td>{{ summary.sales.caliber21Price || 0 | float }} {{ $t('ryal') }}</td>
            </tr>
            <tr>
              <td><img src="../assets/img/svg/gold.svg" class="mx-1" alt="qarat">{{ $t('caliber') + " " + 22 }}</td>
              <td>{{ summary.sales.caliber22Weight || 0 | float }} {{ $t('gram') }}</td>
              <td>{{ summary.sales.caliber22Price || 0 | float }} {{ $t('ryal') }}</td>
            </tr>
            <tr>
              <td><img src="../assets/img/svg/gold.svg" class="mx-1" alt="qarat">{{ $t('caliber') + " " + 24 }}</td>
              <td>{{ summary.sales.caliber24Weight || 0 | float }} {{ $t('gram') }}</td>
              <td>{{ summary.sales.caliber24Price || 0 | float }} {{ $t('ryal') }}</td>
            </tr>
            <tr>
              <th>{{ translation["total"] }}</th>
              <th>{{ summary.sales.totalWeight || 0 | float }} {{ $t('gram') }}</th>
              <th>{{ summary.sales.totalPrice || 0 | float }} {{ $t('ryal') }}</th>
            </tr>
          </tbody>
          <tbody v-if="tableTitle == 'usedInvoices'">
            <tr>
              <td><img src="../assets/img/svg/gold.svg" class="mx-1" alt="qarat">{{ $t('caliber') + " " + 18 }}</td>
              <td>{{ summary.used.caliber18Weight || 0 | float }} {{ $t('gram') }}</td>
              <td>{{ summary.used.caliber18Price || 0 | float }} {{ $t('ryal') }}</td>
            </tr>
            <tr>
              <td><img src="../assets/img/svg/gold.svg" class="mx-1" alt="qarat">{{ $t('caliber') + " " + 21 }}</td>
              <td>{{ summary.used.caliber21Weight || 0 | float }} {{ $t('gram') }}</td>
              <td>{{ summary.used.caliber21Price || 0 | float }} {{ $t('ryal') }}</td>
            </tr>
            <tr>
              <td><img src="../assets/img/svg/gold.svg" class="mx-1" alt="qarat">{{ $t('caliber') + " " + 22 }}</td>
              <td>{{ summary.used.caliber22Weight || 0 | float }} {{ $t('gram') }}</td>
              <td>{{ summary.used.caliber22Price || 0 | float }} {{ $t('ryal') }}</td>
            </tr>
            <tr>
              <td><img src="../assets/img/svg/gold.svg" class="mx-1" alt="qarat">{{ $t('caliber') + " " + 24 }}</td>
              <td>{{ summary.used.caliber24Weight || 0 | float }} {{ $t('gram') }}</td>
              <td>{{ summary.used.caliber24Price || 0 | float }} {{ $t('ryal') }}</td>
            </tr>
            <tr>
              <th>{{ translation["total"] }}</th>
              <td>{{ summary.used.totalWeight  || 0| float }} {{ $t('gram') }}</td>
              <th>{{ summary.used.totalPrice || 0 | float }} {{ $t('ryal') }}</th>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-card>
</template>




<style scoped lang="scss">
thead {
  tr {
    background-color: #E8E8EE !important;

    &:hover {
      background-color: #E8E8EE !important;
    }
  }
}

tr {
  &:hover {
    background: transparent !important;
  }

  &:nth-of-type(even) {
    background-color: #F1F1F3;
  }

  &:last-of-type {
    background-color: #E8E8EE;

    &:hover {
      background-color: #E8E8EE !important;
    }
  }
}

td,
th {
  border-bottom: 0 !important;
}
</style>



<script>
export default {
  name: "SimpleTable",
  computed: {
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  props: {
    tableTitle: { type: String },
    summary: { type: null },
  },
  data: () => ({
    headers: [
      { text: "caliber", value: "caliber" },
      { text: "weight", value: "size" },
      { text: "price", value: "price" },
    ],
  }),

};
</script>
