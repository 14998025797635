export const SalesReportMixins = {
  data: () => ({
    from_date: null,
    to_date: null,
    category_id: null,
    supplier_id: null,
    sub_category_id: null,
    branch_id: null,
    sales_agent_id: null,
    invoice_code: null,
    item_code: null,
    first: [],
    type: 0,
    customer_type: null,
    payment_type: null,
    today: null,
    viewFilter: true,
    reportsRow: [],
    sales_agents: [],
    categories: [],
    delegates: [],
    subcategories: [],
    types: [],
    branches: [],
    printLoading: false,
    printID: null,
    footerData: [{
      weight: 0,
      price: 0,
      invoice_date: null,
      total_vat: 0,
      grand_total: 0,
    }],
  }),
  watch: {
    reportsRow() {
      this.footerData[0].type_title = this.$i18n.t('total');
      this.footerData[0].weight = this.totalPriceCalculation.totalWeight;
      this.footerData[0].price = this.totalPriceCalculation.totalPrice;
      this.footerData[0].fullItemName = this.reportsRow.length + ' ' + this.$t('item');
      this.footerData[0].total_vat = this.totalPriceCalculation.totalVat;
      this.footerData[0].grand_total = this.totalPriceCalculation.totalGrandPrice;
      this.footerData[0].purchase_wages_price = this.totalPriceCalculation.purchase_wages_price;
      this.footerData[0].profit_or_loss_margin = this.totalPriceCalculation.profit_or_loss_margin;
      this.footerData[0].price_per_gram_without_vat = this.$global.NumberWithCommas((+this.totalPriceCalculation.price_per_gram_without_vat || 0).toFixed(2));
      this.footerData[0].price_per_gram_with_vat = this.$global.NumberWithCommas((+this.totalPriceCalculation.price_per_gram_with_vat || 0).toFixed(2));
      this.footerData[0].total_without_vat = this.totalPriceCalculation.totalPrice;
      this.footerData[0].total_with_vat = this.totalPriceCalculation.totalGrandPrice;
    }
  },
  mounted() {
    this.today = this.$global.GetCurrentDate();
    this.to_date = this.$global.GetCurrentDate();
    this.from_date = this.$global.GetFirstDayOfMonth();
  },
  computed: {
    tableHeader() {
      if (this.screen_code === '06-012') { // full report
        return [
          { text: "type", key: "type_title", type: 'text', classes: "" },
          { text: "sales", key: "s_m_id", type: 'text', classes: "" },
          { text: "refund", key: "r_m_id", type: 'text', classes: "" },
          { text: "payment type", key: "payment_type", type: 'text', classes: "" },
          { text: "branch", key: "branch_name", type: 'text', classes: "" },
          { text: "sales agent", key: "sales_agent_name", type: 'text', classes: "" },
          { text: "invoice date", key: "invoice_date", type: 'text', classes: "", width: '85' },
          { text: "weight", key: "weight", type: 'slot', classes: "" },
          { text: "total without vat", key: "total_without_vat", type: 'slot', classes: "" },
          { text: "vats", key: "total_vat", type: 'slot', classes: "" },
          { text: "final total", key: "total_with_vat", type: 'slot', classes: "" },
        ]
      } else { // '06-010' final report
        return [
          { text: "barcode number", key: "item_id", type: 'text', classes: "" },
          { text: "type", key: "type_title", type: 'text', classes: "" },
          { text: "sales", key: "s_m_id", type: 'text', classes: "" },
          { text: "refund", key: "r_m_id", type: 'text', classes: "" },
          { text: "branch", key: "branch_name", type: 'text', classes: "" },
          { text: "sales agent", key: "sales_agent_name", type: 'text', classes: "" },
          { text: "invoice date", key: "invoice_date", type: 'text', classes: "", width: '89' },
          { text: "item", key: "fullItemName", type: 'text', classes: "" },
          { text: "average price per gram before vat", key: "price_per_gram_without_vat", type: 'slot', classes: "", width: '120' },
          { text: "average price per gram after vat", key: "price_per_gram_with_vat", type: 'slot', classes: "", width: '120' },
          { text: "weight", key: "weight", type: 'slot', classes: "" },
          { text: "total without vat", key: "price", type: 'slot', classes: "" },
          { text: "vats", key: "total_vat", type: 'slot', classes: "" },
          { text: "final total", key: "grand_total", type: 'slot', classes: "" },
        ]
      }

    },
    tooltipTableHeader() {
      return [
        { text: "type", key: "type_title", type: 'text', classes: "" },
        { text: "invoice number", key: "s_m_id", type: 'text', classes: "" },
        { text: "return invoice", key: "r_m_id", type: 'text', classes: "" },
        { text: "branch", key: "branch_name", type: 'text', classes: "" },
        { text: "sales agent", key: "sales_agent_name", type: 'text', classes: "" },
        { text: "invoice date", key: "invoice_date", type: 'text', classes: "" },
        { text: "caliber", key: "cat_title", type: 'text', classes: "" },
      ]
    },
    totalCaliberWeight() {
      return (category_id) => {
        return this.reportsRow.reduce((oldValue, newValue) => {
          let sumition = +oldValue + (+newValue.category_id === category_id ? +newValue.weight : 0);
          let subtract = +oldValue - (+newValue.category_id === category_id ? +newValue.weight : 0);
          return newValue.sumtion == 1 ? sumition : subtract;
        }, 0);
      }
    },
    totalCaliberWithVat() {
      return (category_id) => {
        return this.reportsRow.reduce((oldValue, newValue) => {
          let sumition = +oldValue + (+newValue.category_id === category_id ? +newValue.grand_total : 0);
          let subtract = +oldValue - (+newValue.category_id === category_id ? +newValue.grand_total : 0);
          return newValue.sumtion == 1 ? sumition : subtract;
        }, 0);
      }
    },
    totalCaliberWithoutVat() {
      return (category_id) => {
        return this.reportsRow.reduce((oldValue, newValue) => {
          let sumition = +oldValue + (+newValue.category_id === category_id ? +newValue.price : 0);
          let subtract = +oldValue - (+newValue.category_id === category_id ? +newValue.price : 0);
          return newValue.sumtion == 1 ? sumition : subtract;
        }, 0);
      }
    },
    totalCaliberVat() {
      return (category_id) => {
        return this.reportsRow.reduce((oldValue, newValue) => {
          let sumition = +oldValue + (+newValue.category_id === category_id ? +newValue.total_vat : 0);
          let subtract = +oldValue - (+newValue.category_id === category_id ? +newValue.total_vat : 0);
          return newValue.sumtion == 1 ? sumition : subtract;
        }, 0);
      }
    },
    calculateTotal() {
      return (key) => {
        return this.reportsRow.reduce((oldValue, newValue) => {
          let sumition = +oldValue + +newValue[key];
          let subtract = +oldValue - +newValue[key];
          return newValue.sumtion == 1 ? sumition : subtract;
        }, 0);
      }
    },
    totalPriceCalculation() {
      if (this.reportsRow.length > 0) {
        const paidBank = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.paid_bank);
            case 2:
              return Number(+oldValue) - Number(+newValue.paid_bank);
          }
        }, 0);
        const paidCash = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.paid_cash);
            case 2:
              return Number(+oldValue) - Number(+newValue.paid_cash);
          }
        }, 0);
        const totalGrandPrice = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.grand_total);
            case 2:
              return Number(+oldValue) - Number(+newValue.grand_total);
          }
        }, 0);
        const totalWeight = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.weight);
            case 2:
              return Number(+oldValue) - Number(+newValue.weight);
          }
        }, 0);
        const totalPrice = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.price);
            case 2:
              return Number(+oldValue) - Number(+newValue.price);
          }
        }, 0);
        const totalVat = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.total_vat);
            case 2:
              return Number(+oldValue) - Number(+newValue.total_vat);
          }
        }, 0);
        const price_per_gram_without_vat = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.price_per_gram_without_vat);
            case 2:
              return Number(+oldValue) - Number(+newValue.price_per_gram_without_vat);
          }
        }, 0);
        const price_per_gram_with_vat = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.price_per_gram_with_vat || 0);
            case 2:
              return Number(+oldValue) - Number(+newValue.price_per_gram_with_vat || 0);
          }
        }, 0);
        const profit_or_loss_margin = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.profit_or_loss_margin || 0);
            case 2:
              return Number(+oldValue) - Number(+newValue.profit_or_loss_margin || 0);
          }
        }, 0);
        const purchase_wages_price = this.reportsRow.reduce((oldValue, newValue) => {
          switch (newValue.sumtion) {
            case 1:
              return Number(+oldValue) + Number(+newValue.purchase_wages_price || 0);
            case 2:
              return Number(+oldValue) - Number(+newValue.purchase_wages_price || 0);
          }
        }, 0);
        return {
          paidBank: this.$global.NumberWithCommas(paidBank.toFixed(2)),
          paidCash: this.$global.NumberWithCommas(paidCash.toFixed(2)),
          totalGrandPrice: this.$global.NumberWithCommas(totalGrandPrice.toFixed(2)),
          totalGrandPrice2: totalGrandPrice.toFixed(2),
          totalWeight: this.$global.NumberWithCommas(totalWeight.toFixed(2)),
          totalWeight2: +totalWeight?.toFixed(2) || 0,
          totalPrice: this.$global.NumberWithCommas(totalPrice.toFixed(2)),
          totalPrice2: +totalPrice?.toFixed(2) || 0,
          totalVat: this.$global.NumberWithCommas(totalVat.toFixed(2)),
          price_per_gram_without_vat:price_per_gram_without_vat / this.reportsRow.length,
          price_per_gram_with_vat:price_per_gram_with_vat / this.reportsRow.length,
          profit_or_loss_margin: this.$global.NumberWithCommas(profit_or_loss_margin.toFixed(2)),
          purchase_wages_price: this.$global.NumberWithCommas(purchase_wages_price.toFixed(2)),
        }
      } else {
        return {
          paidBank: 0,
          paidCash: 0,
          totalGrandPrice: 0,
          totalWeight: 0,
          totalPrice: 0,
          totalVat: 0,
          price_per_gram_with_vat: 0,
          price_per_gram_without_vat: 0,
          profit_or_loss_margin: 0,
          purchase_wages_price: 0,
        }
      }
    },
  },
  methods: {
    setResponse(response) {
      this.categories = response.data.categorys || [];
      this.subcategories = response.data.sub_categorys || [];
      this.sales_agents = response.data.sales_agents || [];
      this.delegates = response.data.delegates || [];
      this.types = response.data.types || [];
      this.branches = response.data.branchs || [];
      this.pageData.isLoading = false;
    },
    getReports() {
      this.pageData.isLoading = true;
      const body = {
        invoice_code: this.invoice_code,
        from: this.from_date,
        to: this.to_date,
        category_id: this.category_id,
        sub_category_id: this.sub_category_id,
        report_type: this.type,
        customer_type: this.customer_type,
        branch_id: this.branch_id || 0,
        sales_agent_id: this.sales_agent_id || 0,
        payment_type: this.payment_type,
        item_code: +this.item_code,
        type: 'json'
      }
      this.$api.POST_METHOD(this.endpoint, body, false).then((response) => {
        this.pageData.isLoading = false;
        if (response.check) {
          response.data.data.forEach(row => {
            switch (row.category_id) {
              case 25:
                row.cat_title = this.$i18n.t('diamond')
                break;
              case 26:
                row.cat_title = this.$i18n.t('stone')
                break;
              case 50:
                row.cat_title = this.$i18n.t('services')
                break;
              case 925:
                row.cat_title = this.$i18n.t('silver')
                break;
              default:
                row.cat_title = this.$i18n.t('caliber') + ' ' + row.category_id;
                break;
            }
            switch (row.type) {
              case 3:
                row.rowClass = 'green10'
                break;
              case 2:
                row.rowClass = 'red10'
                break;

              default:
                row.rowClass = 'backgroundW'
                break;
            }
            row.payment_type = null
            if (row.paid_cash) {
              row.payment_type = `${row.payment_type ? row.payment_type + ' , ' : ''}  ${this.$i18n.t('cash')}`
            }
            if (row.paid_bank) {
              row.payment_type = `${row.payment_type ? row.payment_type + ' , ' : ''}  ${this.$i18n.t('bank')}`
            }
            if (row.paid_trans) {
              row.payment_type = `${row.payment_type ? row.payment_type + ' , ' : ''}  ${this.$i18n.t('transform')}`
            }
            if (row.paid_tabby) {
              row.payment_type = `${row.payment_type ? row.payment_type + ' , ' : ''}  ${this.$i18n.t('tabby')}`
            }
            if (row.paid_tamara) {
              row.payment_type = `${row.payment_type ? row.payment_type + ' , ' : ''}  ${this.$i18n.t('tamara')}`
            }
            if (row.not_paid) {
              row.payment_type = `${this.$i18n.t('late')}`
            }
            row.type_title = this.types[Number(row.type)].title;
            row.fullItemName = row.item_name + ' #' + row.cat_title;
            row.type_title = this.types[Number(row.type)].title;
            row.weight = row.weight || 0
            row.wages_price = row.wages_price || 0
            row.total_vat = row.total_vat || 0
            row.price = row.price || 0
            row.purchase_wages_price = row.barcode_id ? row.purchase_cost : row.average_purchase_cost
            if (row.weight) {
              row.price_per_gram_without_vat = row.price / row.weight
              row.price_per_gram_with_vat = row.grand_total / row.weight
            } else {
              row.price_per_gram_without_vat = 0
              row.price_per_gram_with_vat = 0
            }
          })
          this.viewFilter = false
          this.reportsRow = response.data.data;
          if (!this.reportsRow.length) {
            this.reportsRow = [{
              s_m_id: null,
              customer_name: null,
              item_name: null,
              invoice_code: 0,
              category_id: 0,
              sub_category_id: 0,
              weight: 0,
              discount: null,
              price: 0,
              total_vat: 0,
              grand_total: 0,
              sales_agent_name: null,
              sub_category_title: null,
              invoice_date: null,
              branch_name: null,
              paid_cash: 0,
              paid_bank: 0,
              paid_trans: 0,
              item_id: 0,
              type: 0,
              sumtion: null,
              cat_title: null,
              rowClass: null,
              type_title: null,
              price_per_gram_without_vat: 0,
              price_per_gram_with_vat: 0
            }]
            this.footerData = [{
              weight: 0,
              price: 0,
              invoice_date: null,
              total_vat: 0,
              grand_total: 0,
            }]
          }
        }
      });
    },
    async printInvoice(id, type) {
      if (type == 's_m_id') {
        this.printLoading = true;
        const src = `${this.$api.serverUrl}export_invoice_sales_qty/${id}?type=print&clientID=${this.$store.state.clientID}`;
        this.$api.PrintInvoiceURL(src, id);
        this.printID = id;
        setTimeout(() => {
          this.printLoading = false;
          this.printID = null;
        }, 4000);
      }
      if (type == 'r_m_id') {
        this.printLoading = true;
        const src = `${this.$api.serverUrl}export_notice_creditor/${id}?type=print&clientID=${this.$store.state.clientID}`;
        this.$api.PrintInvoiceURL(src, id);
        this.printID = id;
        setTimeout(() => {
          this.printLoading = false;
          this.printID = null;
        }, 4000);
      }
    },
  },
};
