<template>
  <v-radio-group class="ma-0" hide-details row
    :rules="required == true ? $global.requiredRule : $global.notRequiredRule"
    :disabled="disabled" v-model="input">
    <v-radio :color="color" v-for="(select, index) in lookups" :key="index" :label="select[selected_label]"
      :value="select[selected_prop]"></v-radio>

    <template v-slot:label>
      <label v-if="labelInline == false">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true">*</span>
      </label>
    </template>
  </v-radio-group>
</template>


<script>
export default {
  name: "RadioInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    lookups: { default: null },
    required: { default: false },
    dense: { default: true },
    lookups_value: { default: null },
    multi: { default: false },
    selected_label: { default: null },
    selected_prop: { default: null },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "primary" },
    icon: { default: "" },
    hideDetails: { default: false },
    labelInline: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    disableItem: { default: null },
    placeholder: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false }
  },
  data: () => ({

  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    checkIsItemDisabled(item) {
      if (this.disableItem) {
        if (item[this.disableItem.key] == this.disableItem.value) {
          return true;
        }
        return false;
      }
      else {
        return false;
      }

    },
  },
};
</script>



<style scoped lang="scss">

</style>
