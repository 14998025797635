<template>
   <section>
      <ShiftPrint2 :expenses="expenses" :is_view="false"  :report="report" :printStaticData="printStaticData"  />
   </section>
</template>



<script>
import ShiftPrint2 from '../Reports/ShiftReports/ShiftPrint2.vue';

export default {
   name: "ShiftReportPrint",
   props: {
      report: { default: Object },
      is_view: { default: false },
      isLastPage: { default: true },
      page: { default: 1 },
      expenses: { default: [] },
      printStaticData: { default: Object },
   },
   data: () => ({

   }),
   watch: {
   },
   components: {
    ShiftPrint2
},
   computed: {

   },
   mounted() {

   },
   methods: {

   },

};
</script>
<style lang="scss" scoped>
.is_view {}

.shift_master_table {
   td {
      padding: 0 0 0 13px !important;
   }

   td,
   th,
   tr {
      background: transparent;

      &:hover {
         background: transparent !important;
      }
   }
}

td {
   padding: 1.2rem 0.4rem;
   font-size: 12px !important;
}

td:nth-of-type(1),
td:nth-of-type(4) {
   color: var(--gray7) !important;
}

.print_title {
   width: fit-content;
   text-align: center;
   font-size: 14px !important;

   color: var(--blue1);
   background: var(--blue12);
   display: block;
   padding: 3px 6px 6px;
   margin: 5px auto;

   div {
      font-size: 16px !important;
      font-weight: bold;
   }
}

.shift_table {
   margin: 20px 0;
   border-collapse: collapse;
   border: 2px solid #e9e8ee;
   border-bottom: 8px solid #28aae1;
   border-right: 8px solid #28aae1;
   background: #fff !important;
   width: 100%;

   tr {


      th {
         z-index: 2;
         position: relative;
         padding: 5px !important;
         background: #e9e8ee !important;
         border: 1px solid #ddd !important;
         font-size: 11px !important;
      }

      td {
         padding: 5px !important;
         font-weight: 600;
         border-bottom: 1px solid #ddd !important;
         font-size: 9px;
         background: #f8f9fd !important;
         border-left: 1px solid #eee;
         text-align: center;

         &:nth-of-type(1) {
            color: #666 !important;
            background: #e6ecff !important;
            padding: 5px 10px !important;
            width: 25% !important;
            text-align: center !important;
         }

         &:nth-of-type(2) {
            text-align: center !important;
            color: #333 !important;
            //direction: ltr;
         }



      }



   }
}
</style>