<template>
  <router-view></router-view>
</template>



<script>

export default {
  name: "Transfers",
  mounted() {
    document.title = this.$i18n.t("Qarat - Transfers");
  }
};
</script>
