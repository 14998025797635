<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <v-row align="center" justify="center" justify-md="start">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center me-3 text--disabled">
                <strong>{{ $t('transfers') | capitalize }}</strong>
              </div>
            </v-col>
            <v-col cols="auto" class="px-1" v-for="(tab, index) in tabs.filter(tab => tab.isAuth)" :key="index">
              <v-btn :outlined="activeTab !== tab.id" :color="activeTab == tab.id ? 'primary' : 'gray6'"
                :min-width="120" class="rounded-xl" :disabled="isLoading && activeTab !== tab.id"
                :loading="isLoading && activeTab == tab.id" @click="activeTab = tab.id" depressed>
                {{ $t(tab.name) }}
              </v-btn>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-2">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :searchByDateRange="false" :viewTitle="true"
            :option="options" :editValueMethod="edit" printURL="print_request" createBtnLabel="add" :data="rows"
            :header="tableHeader" :filterClicked="filterClicked" :controlRoute="controlRoute">
            <template v-slot:inputs>
              <v-btn :height="40" depressed v-if="activeTab == 1 || activeTab == 2"
                :color="isActive ? 'red12' : 'blue12'" :class="isActive ? 'red1--text' : 'blue1--text'"
                @click="isActive = isActive ? 0 : 1">
                {{ isActive ? $t('show deleted') : $t('show active') }}
              </v-btn>
            </template>

            <template v-slot:td="{ row, header }">

              <div v-if="header.key == 'actions'">
                <v-btn icon :loading="printLoading?.id === row.id" @click="printRow(row)">
                  <v-icon size="22" color="info">mdi-printer</v-icon>
                </v-btn>
                <v-btn @click="edit(row)" class="blue12 ma-1" x-small icon fab>
                  <v-icon color="blue1">mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn @click="toggle(row.id)" v-if="row.transfer_status == 1 && (activeTab == 1 || activeTab == 2)"
                  x-small class="ma-1" :class="isActive ? 'red12' : 'green12'"
                  :loading="isDeleteLoading && row.id == actionItem.id" icon fab>
                  <v-icon v-if="isActive" color="error">mdi-delete</v-icon>
                  <v-icon v-else color="green1">mdi-restore</v-icon>
                </v-btn>
              </div>

              <div v-if="header.key == 'status'">

                <v-chip color="red12"
                  v-if="row.transfer_status == 1 && $store.state.userData?.user?.branch_data?.id !== row.to_branch_id && (activeTab == 2 || activeTab == 3)"
                  class="red1--text font-weight-bold" small>
                  {{ $t('not received') }}</v-chip>

                <v-chip color="red12" v-if="row.transfer_status == 1 && (activeTab == 1 || activeTab == 4)"
                  class="red1--text font-weight-bold" small>
                  {{ $t('not received') }}</v-chip>

                <v-chip color="green12" class="green1--text font-weight-bold" v-if="row.transfer_status == 2" small>
                  {{ $t('received') }}</v-chip>

                <v-chip color="yellow10" class="yellow1--text font-weight-bold" v-if="row.transfer_status == 3" small>
                  {{ $t('rejected') }}</v-chip>

                <span
                  v-if="$store.state.userData?.user?.branch_data?.id === row.to_branch_id && (activeTab == 2 || activeTab == 3)">
                  <v-btn color="success" :loading="statusLoading && row.id == actionItem.id"
                    v-if="row.transfer_status == 1" @click="changeStatus(2, row.id)" small class="font-weight-bold mx-1"
                    depressed>{{ $t('receive') }}
                    <v-icon right>mdi-check</v-icon></v-btn>

                  <v-btn color="yellow10" :loading="statusLoading && row.id == actionItem.id"
                    v-if="row.transfer_status == 1" @click="changeStatus(3, row.id)" depressed
                    class="yellow1--text font-weight-bold mx-1" small>
                    {{ $t('reject') }}
                    <v-icon right>mdi-close</v-icon></v-btn>
                </span>
              </div>
            </template>

          </DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" class="py-0" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col>
      </v-row>

      <ExportNow :printNow="printNow" :excel="printStaticData.excel" :exportData="printData"
        :header="printStaticData.tableHeader" :footerData="[]" :groupHeader="[]"
        :attachmentFileName="printStaticData.title + ' - ' + printStaticData?.master?.id"
        :printTitle="printStaticData.title + ' - ' + printStaticData?.master?.id">
        <template v-slot:pdf_header="{ page }">
          <div v-if="page == 1"
            style="background : #e8e8ea; width: 96%; padding : 5px 10px; display:flex ; justify-content: space-between;margin:10px auto auto auto; font-size: 12px; font-weight: 500;">
            <div>{{ $t('date') }} : {{ printStaticData?.master?.action_date }}</div>
            <div>{{ printStaticData?.master?.from_branch_id ? $t('tranfer from branch') : $t('tranfer from bank') }} :
              {{ printStaticData?.master?.from_branch_name || printStaticData?.master?.from_bank_name }}</div>
            <div>{{ printStaticData?.master?.to_branch_id ? $t('tranfer to branch') : $t('tranfer to bank') }} :
              {{ printStaticData?.master?.to_branch_name || printStaticData?.master?.to_bank_name }}</div>
          </div>
        </template>
        <template v-slot:pdf_footer="{ isLastPage }">
          <div v-if="isLastPage && (activeTab == 2 || activeTab == 3)"
            style="position:absolute;height:180px; width:100%;top:-185px;left :0">
            <table width="100%" id="printTable">
              <tr>
                <th>{{ $t("total") }}</th>
                <th>{{ $t("caliber") }} 18</th>
                <th>{{ $t("caliber") }} 21</th>
                <th>{{ $t("caliber") }} 22</th>
                <th>{{ $t("caliber") }} 24</th>
                <th>{{ $t("diamond") }}</th>
                <th>{{ $t("stone") }}</th>
                <th>{{ $t("count") }}</th>
              </tr>
              <tr>
                <td class="blue12 font-weight-bold">{{ $t("weight") }}</td>
                <td>{{ totalCaliberWeight(18) | nonZero }}</td>
                <td>{{ totalCaliberWeight(21) | nonZero }}</td>
                <td>{{ totalCaliberWeight(22) | nonZero }}</td>
                <td>{{ totalCaliberWeight(24) | nonZero }}</td>
                <td>{{ totalCaliberWeight(25) | nonZero }}</td>
                <td>{{ totalCaliberWeight(26) | nonZero }}</td>
                <td>{{ printData.length }}</td>
              </tr>
            </table>
            <div id="signature" style="margin-top:70px">
              {{ $t('receiver signature') }}
            </div>
          </div>
        </template>
      </ExportNow>

      <TransferFilter :dialog="filterDialog" :closeDialog="() => filterDialog = false" :filterAction="filterAction" />
      <DeleteConfirmation :dialog="deleteDialog" :item="null" :backValueMethod="deleteMethod" />

      <v-dialog v-model="setPasswordDialog" overlay-opacity="0.75" persistent max-width="452">
        <v-card relative class="backgroundW py-8">

          <v-card-title class="text-h6 mt-3 text-center font-weight-bold">
            <h5 class="d-block primary--text ma-auto">
              <span>{{ $t('branch password') }}</span>
            </h5>
          </v-card-title>


          <v-col cols="12" class="mt-3 px-8">
            <v-row justify="center">

              <v-col cols="11" dir="ltr">
                <v-otp-input dir="ltr" type="number" length="4" autofocus @finish="changeStatusRequest"
                  v-model="actionItem.password"></v-otp-input>
              </v-col>
              <v-col cols="auto">
                <v-btn @click="setPasswordDialog = false" :disabled="actionItem.is_loading" :height="52" :width="170"
                  depressed class="rounded-lg font-weight-bold  error--text" color="white">{{ $t('cancel') }}</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" depressed :disabled="!(actionItem.password)" :loading="actionItem.is_loading"
                  @click="changeStatusRequest" :height="52" :width="170"
                  class="rounded-lg font-weight-bold white--text">
                  {{ $t('submit') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-dialog>
    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import Pagination from "@/components/Pagination";
import DeleteConfirmation from "@/components/modals/DeleteConfirmation.vue";
import TransferFilter from "./TransferFilter";
import ExportNow from "@/components/ui/ExportNow.vue";
export default {
  name: "TransfersTable",

  data: () => ({
    isLoading: false,
    statusLoading: false,
    isActive: 1,
    controlRoute: '/transfers/cash_transfer',
    filterDialog: false,
    isDeleteLoading: false,
    deleteDialog: false,
    selectedRow: {},
    options: null,
    rows: [],
    pagination: null,
    page: 1,
    rowsNumber: 25,
    searchText: '',
    today: null,
    tabs: [],
    activeTab: 1,
    actionItem: Object,
    filter: {
      from_date: null,
      to_date: null,
      negative_difference: null,
      positive_difference: null,
    },
    printNow: false,
    setPasswordDialog: false,
    printLoading: {
      loading: false,
      id: null,
      key: 'id'
    },
    printStaticData: {
      tableHeader: [],
      master: {},
      excel: false,
    },
    printData: [],
  }),
  components: {
    DynamicTable,
    Pagination,
    TransferFilter,
    DeleteConfirmation,
    ExportNow,
  },
  watch: {
    activeTab() {
      switch (this.activeTab) {
        case 1:
          this.controlRoute = '/transfers/cash_transfer';
          break;
        case 2:
          this.controlRoute = '/transfers/metal_transfer';
          break;
        default:
          this.isActive = 1
          this.controlRoute = null;
          break;
      }
      this.page = 1;
      this.searchText = '';
      this.getData();
    },
    isActive() {
      this.getData();
    }
  },
  computed: {
    tableHeader() {
      switch (this.activeTab) {
        case 1:
        case 4:
          return [
            { text: "serial", key: "id", type: 'text', classes: "", },
            { text: "action date", key: "action_date", type: 'date', classes: "" },
            { text: "description", key: "description", type: 'text', classes: "" },
            { text: "transfer type", key: "transfer_type_title", type: 'text', classes: "" },
            { text: "from", key: "source", type: 'text', classes: "" },
            { text: "to", key: "distention", type: 'text', classes: "" },
            { text: "amount", key: "amount", type: 'float', classes: "" },
            { text: "status", key: "status", type: 'slot', classes: "", },
            { text: "", key: "actions", type: 'slot', hideInPrint: false, classes: "", },]
        case 2:
        case 3:
          return [
            { text: "serial", key: "id", type: 'text', classes: "", },
            { text: "action date", key: "action_date", type: 'date', classes: "" },
            { text: "description", key: "description", type: 'text', classes: "" },
            { text: "transfer from", key: "from_branch_name", type: 'text', classes: "" },
            { text: "transfer to", key: "to_branch_name", type: 'text', classes: "" },
            { text: "count", key: "count", type: 'text', classes: "" },
            { text: "status", key: "status", type: 'slot', classes: "", },
            { text: "response date", key: "response_date", type: 'date', classes: "" },
            { text: "", key: "actions", type: 'slot', hideInPrint: false, classes: "", },]

        default:
          return []
      }
    },
    totalCaliberWeight() {
      return (category_id) => {
        return this.printData.reduce((oldValue, newValue) => {
          let weight = 0;
          switch (+category_id) {
            case 25:
              weight = +newValue.diamond_weight
              break;
            case 26:
              weight = +newValue.stone_weight
              break;
            default:
              weight = +newValue.gold_weight
              break;
          }
          let sum = +oldValue + (+newValue.category_id === category_id ? weight : 0);
          return sum;
        }, 0);
      }
    },
  },
  mounted() {
    this.pageMainData();
    this.getData();

    this.tabs = [
      {
        id: 1,
        name: 'Cash',
        isAuth: this.$global.CheckAction("06-021", 1),
      },
      {
        id: 2,
        name: 'metals',
        isAuth: this.$global.CheckAction("06-022", 1),
      },
      {
        id: 3,
        name: 'follow transfer metals',
        isAuth: this.$global.CheckAction("06-022", 1),
      },
      {
        id: 4,
        name: 'follow transfer cash',
        isAuth: this.$global.CheckAction("06-021", 1),
      },
    ]
  },
  methods: {
    pageMainData() {

      this.options = {
        print: true,
        delete: false,
        edit: false,
        restore: false,
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },

    getData() {
      this.isLoading = true;
      let endPoint = '';
      switch (this.activeTab) {
        case 1:
        case 4:
          endPoint = 'money_transfer'
          break;
        case 2:
        case 3:
          endPoint = 'metal_transfer'
          break;
        default:
          endPoint = 'money_transfer'
          break;
      }
      this.$api.GET_METHOD(`${endPoint}?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText || ''}&active=${this.isActive}&from_date=${this.filter.from_date || ''}&to_date=${this.filter.to_date || ''}&positive_difference=${this.filter.positive_difference || ''}&negative_difference=${this.filter.negative_difference || ''}`)
        .then((response) => {
          this.isLoading = false;
          response.data.items.data.forEach(row => {
            if (row.transfer_type) {
              row.transfer_type_title = row.from_bank_id ? this.$t('bank') : this.$t('branch')
              row.source = row.from_bank_id ? row.from_bank_name : row.from_branch_name
              row.distention = row.to_bank_id ? row.to_bank_name : row.to_branch_name
            }
          });
          this.rows = response.data.items.data;
          this.page = response.data.items.current_page;
          this.pagination = {
            page: response.data.items.current_page,
            totalPages: response.data.items.last_page,
            per_page: response.data.items.per_page,
            totalRows: response.data.items.total,
          };
        })

    },
    filterClicked() {
      this.filterDialog = true;
    },
    filterAction(filter) {
      this.filter = filter;
      this.getData();
      this.filterDialog = false;
    },
    edit(row) {
      if (this.activeTab == 1 || this.activeTab == 4) {
        this.$router.push(`/transfers/${row.id}?type=cash`)
      }
      if (this.activeTab == 2 || this.activeTab == 3) {
        this.$router.push(`/transfers/${row.id}?type=metal`)
      }
    },

    searchMethod(text) {
      this.searchText = text;
      if (!this.isLoading) {
        this.getData()
      }
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
    deleteMethod(status) {
      if (status == true) {
        this.isDeleteLoading = true;
        let endPoint = '';
        switch (this.activeTab) {
          case 1:
          case 4:
            endPoint = 'money_transfer'
            break;
          case 2:
          case 3:
            endPoint = 'metal_transfer'
            break;
          default:
            endPoint = 'money_transfer'
            break;
        }
        this.deleteDialog = false;
        this.$api.POST_METHOD(`${endPoint}/${this.actionItem.id}`, { _method: 'delete' }).then((response) => {
          this.isDeleteLoading = false
          if (response.check) {
            const getIndex = this.rows.findIndex((row) => row.id == this.actionItem.id);
            this.rows.splice(getIndex, 1);
            this.actionItem = Object;
          }
        })
      } else {
        this.deleteDialog = false;
        this.actionItem = Object;
      }
    },
    toggle(id) {
      this.actionItem.id = id
      this.isDeleteLoading = true;
      let endPoint = '';
      switch (this.activeTab) {
        case 1:
        case 4:
          endPoint = 'money_transfer_toggle_active'
          break;
        case 2:
        case 3:
          endPoint = 'metal_transfer_toggle_active'
          break;
        default:
          endPoint = 'money_transfer_toggle_active'
          break;
      }
      this.deleteDialog = false;
      this.$api.POST_METHOD(`${endPoint}/${id}`).then((response) => {
        this.isDeleteLoading = false
        if (response.check) {
          const getIndex = this.rows.findIndex((row) => row.id == id);
          this.rows.splice(getIndex, 1);
          this.actionItem = Object;
        }
      })
    },
    changeStatus(status, id) {
      this.actionItem = {
        id: id,
        status: status,
        is_loading: false,
        password: null,
      }
      if (this.$store?.state?.userData?.user?.branch_data?.branch_protected && (this.activeTab == 2 || this.activeTab == 3)) {
        this.setPasswordDialog = true;
      } else {
        this.changeStatusRequest();
      }
    },
    changeStatusRequest() {
      let endPoint = ''
      switch (this.activeTab) {
        case 1:
        case 4:
          endPoint = 'money_transfer_response'
          break;
        case 2:
        case 3:
          endPoint = 'metal_transfer_response'
          break;
        default:
          endPoint = 'money_transfer_response'
          break;
      }
      this.statusLoading = true;
      this.actionItem.is_loading = true;
      this.$api.POST_METHOD(`${endPoint}/${this.actionItem.id}`, { status: this.actionItem.status, password: this.actionItem.password }).then((response) => {
        this.statusLoading = false
        this.actionItem.is_loading = false;
        if (response.check) {
          const getIndex = this.rows.findIndex((row) => row.id == this.actionItem.id);
          this.rows[getIndex].transfer_status = this.actionItem.status
          this.setPasswordDialog = false;
          this.actionItem = {};
        }
      })
    },
    printRow(row) {
      let endPoint = '';
      switch (this.activeTab) {
        case 1:
        case 4:
          endPoint = 'money_transfer'
          this.printStaticData.title = this.$t('money transfer')
          this.printStaticData.tableHeader = [
            { text: "amount", key: "amount", type: 'float' },
            { text: "from branch", key: "from_branch_name", type: 'text' },
            { text: "from bank", key: "from_bank_name", type: 'text' },
            { text: "to branch", key: "to_branch_name", type: 'text' },
            { text: "to bank", key: "to_bank_name", type: 'text' },
          ];
          break;
        case 2:
        case 3:
          endPoint = 'metal_transfer';
          this.printStaticData.title = this.$t('metal transfer')
          this.printStaticData.tableHeader = [
            { text: "category", key: "category_title", type: 'text' },
            { text: "item", key: "sub_category_title", type: 'text' },
            { text: "gold weight", key: "gold_weight", type: 'float' },
            { text: "diamond weight", key: "diamond_weight", type: 'float' },
            { text: "stone weight", key: "stone_weight", type: 'float' },
          ];
          break;
        default:
          endPoint = 'money_transfer';
          this.printStaticData.title = this.$t('money transfer')
          this.printStaticData.tableHeader = [
            { text: "amount", key: "amount", type: 'float' },
            { text: "from branch", key: "from_branch_name", type: 'text' },
            { text: "from bank", key: "from_bank_name", type: 'text' },
            { text: "to branch", key: "to_branch_name", type: 'text' },
            { text: "to bank", key: "to_bank_name", type: 'text' },
          ];
          break;
      }
      this.printLoading.loading = true;
      this.printLoading.id = row.id
      this.$api.GET_METHOD(`${endPoint}/${row.id}`).then((response) => {
        this.printLoading.id = 0;
        if (response.check) {
          switch (this.activeTab) {
            case 1:
            case 4:
              this.printStaticData.master = response.data?.data;
              this.printData = [response.data?.data];
              break;
            case 2:
            case 3:
              this.printStaticData.master = response.data?.master;
              this.printData = response.data?.details;
              break;
            default:
              this.printStaticData.master = response.data?.data;
              this.printData = [response.data?.data];
              break;
          }
          this.printNow = true;
          setTimeout(() => {
            this.printNow = false;
            this.printData = [];
            this.printStaticData = {};
            this.printLoading.loading = false;
          }, 1000);

        } else {
          this.printLoading.loading = false;
        }
      });
    },
  },
};
</script>
