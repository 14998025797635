<template>
  <section>
    <v-container fluid>
      <v-card class="shadow">
        <v-col cols="12" lg="10" md="11" class="d-flex mx-auto align-center">
          <v-text-field class="my-2 border" outlined background-color="light"
            :placeholder="$i18n.t('search by invoice number')" v-model="search" @keydown.enter="searchReturnSales"
            rounded-md dense autofocus hide-details type="number" @click="seachResult = []"></v-text-field>
          <v-btn @click="searchReturnSales" :disabled="loading" :loading="loading" class="my-2 mx-2 shadow"
            color="primary" :height="40">
            <v-icon dark left>mdi-search-web</v-icon>
            {{ $t('search') }}
          </v-btn>
        </v-col>

        <v-row justify="center" v-if="isLoading">
          <v-col cols="auto">
            <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
              indeterminate></v-progress-circular>
            <p class="text-center">{{ $t('Loading data') }}</p>
          </v-col>
        </v-row>

        <v-form ref="form" v-model="valid" class="pa-5" v-if="seachResult.length > 0">
          <v-card class="backgroundW shadow pa-5">
            <v-row>
              <v-col cols="12" lg="3" sm="6">
                <label>{{ $t('customerName') }}*</label>
                <v-text-field class="my-2" outlined background-color="light" v-model="form.customer_name" rounded-md dense
                  readonly type="text"></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" sm="6">
                <label>{{ $t('customerMobile') }}*</label>
                <v-text-field class="my-2" outlined background-color="light" v-model="form.customer_mobile" rounded-md
                  dense readonly type="number"></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" sm="6">
                <label>{{ $t('Identification Number') }}*</label>
                <v-text-field class="my-2" outlined background-color="light" v-model="form.customer_id_number" rounded-md
                  dense readonly type="number"></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" sm="6">
                <label>{{ $t('Registration tax number') }}*</label>
                <v-text-field class="my-2" outlined background-color="light" v-model="form.customer_vat_register_number"
                  rounded-md dense readonly type="number"></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-form>

        <!-- Table -->
        <v-col cols="12" sm="12" v-if="seachResult.length > 0">
          <v-card class="backgroundW shadow pa-5">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">{{ $t('item') }}</th>
                    <th class="text-center">{{ $t('caliber') }}</th>
                    <th class="text-center">{{ $t('weight') }}</th>
                    <th class="text-center">{{ $t('pieces') }}</th>
                    <!-- <th class="text-center">{{ $t('minimum price') }}</th> -->
                    <th class="text-center">{{ $t('vat') }}</th>
                    <th class="text-center">{{ $t('Total including tax') }}</th>
                    <th class="text-center">{{ $t('edit') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in seachResult" :key="index">
                    <td class="text-center">{{ row.sub_category_title }}</td>
                    <td class="text-center">{{ row.category_title }}</td>
                    <td class="text-center">{{ $global.DecimalFormatter(row.current_weight.toFixed(2)) }} {{ $t('gram')
                    }}</td>
                    <td class="text-center">{{ $global.DecimalFormatter(row.current_pieces.toFixed(2)) }} {{ $t('piece')
                    }}</td>
                    <!-- <td
                      class="text-center"
                    >{{ $global.DecimalFormatter(Number(row.grand_total).toFixed(2)) }} {{ $t('ryal') }}</td>-->
                    <td class="text-center">{{ $global.DecimalFormatter(Number(row.current_vat).toFixed(2)) }} {{
                      $t('ryal')
                    }}</td>
                    <td class="text-center">{{ $global.DecimalFormatter(Number(row.current_grand_total).toFixed(2)) }}
                      {{ $t('ryal') }}</td>

                    <td class="d-flex align-center justify-center" style="height: 70px;">
                      <div class="mx-3">
                        <div class="my-0 d-flex justify-space-between">
                          <div class="mx-2">{{ $t("weight") }} :</div>
                          <div>{{ $global.DecimalFormatter(Number(row.new_weight).toFixed(2)) }}</div>
                        </div>
                        <div class="my-0 d-flex justify-space-between">
                          <div class="mx-2">{{ $t("pieces") }} :</div>
                          <div>{{ $global.DecimalFormatter(Number(row.new_pieces).toFixed(2)) }}</div>
                        </div>
                        <div class="my-0 d-flex justify-space-between">
                          <div class="mx-2">{{ $t("price") }} :</div>
                          <div>{{ $global.DecimalFormatter(Number(row.new_price).toFixed(2)) }}</div>
                        </div>
                      </div>
                      <v-icon color="primary" @click="openDialog(row)" class="cursor_pointer mx-3">mdi-plus-circle
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

        </v-col>
      </v-card>

      <v-col cols="12" class="px-0 mt-2" v-if="seachResult.length > 0">
        <v-card class="shadow rounded-lg justify-center pa-5">
          <v-row justify="space-between" align="center">
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="px-4">
                  <PaymentMethodNotice :type="payment_type_title" :backValueMethod="setPayment" :machineID="machineId">
                  </PaymentMethodNotice>
                </v-col>
                <v-col cols="auto" class="px-4">
                  <BankModal :machines="machines" :machine="machineId" :backValueMethod="setMachine"></BankModal>
                </v-col>
                <v-col cols="auto" class="px-4">
                  <SellarModal :checkChangeAgent="false" :sales_agents="sales_agents" :sellar="salesAgent" :backValueMethod="setAgent">
                  </SellarModal>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row align="center">
                <!-- <v-col cols="auto">
                  <div class="font-weight-bold body-2 mt-2">{{ $t('totalPaid') }} : {{ totalPaid | float }}</div>
                  <div class="font-weight-bold body-2 mt-2">{{ $t('finalCash') }} : {{
                      $store.state.InvoiceStore.totalPrice
                      | float
                  }}</div>
                </v-col> -->
                <!-- <v-col cols="auto">
                  <v-btn class="shadow" @click="resetPayment" color="error" outlined>{{ $t('deletePaid') }}
                  </v-btn>
                </v-col> -->
                <v-col cols="auto">
                  <v-btn @click="save" :disabled="!(saveValid)" :loading="loading" class="shadow d-block mx-2"
                    color="success" v-if="seachResult.length > 0">{{
                      $t('save invoice')
                    }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-dialog v-model="noteDialog" overlay-opacity="0.75" :width="850">
        <v-card relative flat class="pa-0">
          <img class="d-block ma-auto" src="@/assets/img/png/invoice_warning.webp" width="850" alt="">
        </v-card>
      </v-dialog>

      <ReturnNoticeModal :backValueMethod="setData" :setRow="setRow" :dialog="dialog" :row="selectedRow"
        :loading="loading" :noticeType="'debtor'"></ReturnNoticeModal>
    </v-container>
  </section>
</template>



<script>
import ReturnNoticeModal from "@/components/modals/ReturnNoticeModal.vue";
import PaymentMethodNotice from "@/components/modals/PaymentMethodNotice.vue";
import BankModal from "@/components/modals/BankModal.vue";
import SellarModal from "@/components/modals/SellarModal.vue";
export default {
  name: "DebtorCreate",
  computed: {
    saveValid() {
      if (this.payment_type && this.returnedNoticeItems.length > 0) {
        return true
      } else {
        return false
      }
    },
    totalPaid() {
      return this.payments.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.amount);
      }, 0);
    }
  },
  data: () => ({
    search: '',
    valid: false,
    dialog: false,
    loading: false,
    isLoading: false,
    confirmationDialog: false,
    seachResult: [],
    machineId: null,
    salesAgent: null,
    machines: [],
    sales_agents: [],
    payment_type: 1,
    payment_type_title: 'payCash',
    key: 1,
    form: {
      customer_name: '',
      customer_mobile: '',
      customer_id_number: '',
      customer_vat_register_number: '',
    },
    returnedNoticeItems: [],
    noteDialog: false,
    selectedRow: {},
    printContent: {},
  }),
  components: {
    ReturnNoticeModal,
    PaymentMethodNotice,
    BankModal,
    SellarModal
  },
  watch: {
    returnedNoticeItems() {
      this.$store.state.InvoiceStore.totalPrice = this.returnedNoticeItems.reduce((oldValue, newValue) => {
        console.log(Number(+oldValue) + Number(+newValue.new_price));
        return Number(+oldValue) + Number(+newValue.new_price);
      }, 0);
    },
    noteDialog() {
      if (this.noteDialog) {
        setTimeout(() => {
          this.noteDialog = false
        }, 4500);
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$api.GET_METHOD(`get_create_notice_debtor_invoice`).then((response) => {
        if (response.check) {
          this.sales_agents = response.data.sales_agents
          this.machines = response.data.machines
          if (response.data.sales_agents.length > 0) {
            this.salesAgent = response.data.sales_agents[0]
          }
          if (response.data.machines.length > 0) {
            this.machineId = response.data.machines[0]
          }
        }
      })
    },
    setRow(item) {
      if (this.returnedNoticeItems.some((old => old.d_id == item.d_id))) {
        let remove = this.returnedNoticeItems.indexOf((old => old.d_id == item.d_id))
        console.log(remove);
        this.returnedNoticeItems.splice(remove, 1);
        this.returnedNoticeItems.push(item);
      }
      else {
        this.returnedNoticeItems.push(item);
      }
    },
    save() {
      this.loading = true;
      let requestBody = {
        items: this.returnedNoticeItems,
        invoice: this.form.m_id,
        sales_invoice_type: this.$store.state.userData.user.shift.stock_type,
        payment_type: this.payment_type,
        salesAgent: this.salesAgent.id,
        machineId: Number(+this.machineId.id),
      }

      this.$api.POST_METHOD("create_notice_debtor_invoice", requestBody, true, this.$i18n.t('Invoice Created Successfully')).then((response) => {
        this.loading = false;
        if (response.check) {
          this.search = '';
          this.$refs.form.reset();
          this.seachResult = [];
          this.returnedNoticeItems = [];
          // this.$api.DOWNLOAD_METHOD(`export_notice_debtor/${response.data}?type=pdf`);
          const src = `src="${this.$api.serverUrl}export_notice_debtor/${response.data}?type=image&clientID=${this.$store.state.clientID}"`;
          this.$api.PrintInvoice(src, response.data);
          this.key = this.key + 1,
            this.$store.state.InvoiceStore.totalPrice = 0;
          this.resetPayment()
        }
      })
    },
    searchReturnSales() {
      if (this.search) {
        this.isLoading = true;
        let endPoint = this.$store.state.userData.user.shift.stock_type == 1 ? 'get_sales_invoice_by_pieces' : 'get_sales_invoice_by_qty';
        this.$api.GET_METHOD(`${endPoint}/invoice?invoiceCode=${this.search}`).then((response) => {
          this.isLoading = false;
          // console.log(response);
          this.seachResult = []
          response.data.details.forEach(row => {
            row.new_price = 0;
            row.new_weight = 0;
            row.new_pieces = 0;
            return this.seachResult.push(row)
          }, 0);
          this.form.m_id = response.data?.master?.m_id;
          this.form.customer_name = response.data.master.customer_name
          this.form.customer_mobile = response.data.master.customer_mobile
          this.form.customer_id_number = response.data.master.customer_id_number
          this.form.customer_vat_register_number = response.data.master.customer_vat_register_number

        })

      } else {
        this.$swal.fire({
          title: this.$t("Please Check"),
          text: this.$t("please write invoice code"),
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    openDialog(row) {
      this.selectedRow = row
      this.dialog = true;
    },
    setData(data, status) {
      console.log("changed data", data);
      this.dialog = status
    },
    setMachine(returnedValue) {
      this.machineId = returnedValue;
    },
    setAgent(returnedValue) {
      this.salesAgent = returnedValue;
    },
    setPayment(returnedValue) {
      this.payment_type = returnedValue;
      switch (returnedValue) {
        case 1:
          this.payment_type_title = 'payCash';
          break;
        case 2:
          this.payment_type_title = 'payBank';
          break;
        case 3:
          this.payment_type_title = 'transferPayment';
          break;

        default:
          break;
      }
    },
    resetPayment() {
      this.payment_type = null;
      this.payment_type_title = null;
    },
  },
};
</script>

<style  lang="scss"></style>