<template>
  <div style="display: flex; width :100%; position: relative;margin-top : -170px" class="mt-0" v-if="reportsRow.length">
    <table style="width:70% !important" class="mini_table_two shadow backgroundW" id="printTable">
      <tr>
        <th style="font-size: 12px !important;">{{ $t("total") }}</th>
        <th style="font-size: 12px !important;">{{ $t("caliber") }} 18</th>
        <th style="font-size: 12px !important;">{{ $t("caliber") }} 21</th>
        <th style="font-size: 12px !important;">{{ $t("caliber") }} 22</th>
        <th style="font-size: 12px !important;">{{ $t("caliber") }} 24</th>
        <th style="font-size: 12px !important;">{{ $t("diamond") }}</th>
        <th style="font-size: 12px !important;">{{ $t("stone") }}</th>
        <th style="font-size: 12px !important;">{{ $t("services") }}</th>
      </tr>
      <tr>
        <td style="font-size: 12px !important;" class="blue12 font-weight-bold">{{ $t("weight") }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWeight(18) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWeight(21) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWeight(22) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWeight(24) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWeight(25) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWeight(26) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWeight(50) | nonZero }}</td>
      </tr>
      <tr>
        <td style="font-size: 12px !important;" class="blue12 font-weight-bold">{{ $t("without vat") }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithoutVat(18) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithoutVat(21) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithoutVat(22) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithoutVat(24) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithoutVat(25) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithoutVat(26) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithoutVat(50) | nonZero }}</td>
      </tr>
      <tr>
        <td style="font-size: 12px !important;" class="blue12 font-weight-bold">{{ $t("vat") }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberVat(18) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberVat(21) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberVat(22) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberVat(24) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberVat(25) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberVat(26) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberVat(50) | nonZero }}</td>
      </tr>
      <tr>
        <td style="font-size: 12px !important;" class="blue12 font-weight-bold">{{ $t("with vat") }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithVat(18) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithVat(21) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithVat(22) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithVat(24) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithVat(25) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithVat(26) | nonZero }}</td>
        <td style="font-size: 12px !important;">{{ totalCaliberWithVat(50) | nonZero }}</td>
      </tr>
    </table>
    <table style="width:30% !important" class="mini_table_two shadow backgroundW"  id="printTable">
      <tr>
        <th style="font-size: 12px !important;">{{ $t("payment method") }}</th>
        <th style="font-size: 12px !important;">{{ $t("total") }}</th>
      </tr>
      <tr>
        <td style="font-size: 12px !important;" class="blue12 font-weight-bold">{{ $t("cash") }}</td>
        <td style="font-size: 12px !important;">{{ calculateTotal('paid_cash') | nonZero }}</td>
      </tr>
      <tr>
        <td style="font-size: 12px !important;" class="blue12 font-weight-bold">{{ $t("bank") }}</td>
        <td style="font-size: 12px !important;">{{ calculateTotal('paid_bank') | nonZero }}</td>
      </tr>
      <tr>
        <td style="font-size: 12px !important;" class="blue12 font-weight-bold">{{ $t("transfer") }}</td>
        <td style="font-size: 12px !important;">{{ calculateTotal('paid_trans') | nonZero }}</td>
      </tr>
      <tr>
        <td style="font-size: 12px !important;" class="blue12 font-weight-bold">{{ $t("tabby") }}</td>
        <td style="font-size: 12px !important;">{{ calculateTotal('paid_tabby') | nonZero }}</td>
      </tr>
      <tr>
        <td style="font-size: 12px !important;" class="blue12 font-weight-bold">{{ $t("tamara") }}</td>
        <td style="font-size: 12px !important;">{{ calculateTotal('paid_tamara') | nonZero }}</td>
      </tr>
      <tr>
        <td style="font-size: 12px !important;" class="blue12 font-weight-bold">{{ $t("total") }}</td>
        <td style="font-size: 12px !important;">{{ calculateTotal('paid_cash') + calculateTotal('paid_bank') + calculateTotal('paid_trans') + calculateTotal('paid_tabby') + calculateTotal('paid_tamara') | nonZero }}</td>
      </tr>
    </table>
  </div>
</template>



<style scoped lang="scss"></style>



<script>
export default {
  name: "ReportTotals",
  props: {
    reportsRow: { default: [] },
  },
  computed: {
    totalCaliberWeight() {
      return (category_id) => {
        return this.reportsRow.reduce((oldValue, newValue) => {
          let sumition = +oldValue + (+newValue.category_id === category_id ? +newValue.weight : 0);
          let subtract = +oldValue - (+newValue.category_id === category_id ? +newValue.weight : 0);
          return newValue.sumtion == 1 ? sumition : subtract;
        }, 0);
      }
    },
    totalCaliberWithVat() {
      return (category_id) => {
        return this.reportsRow.reduce((oldValue, newValue) => {
          let sumition = +oldValue + (+newValue.category_id === category_id ? +newValue.grand_total : 0);
          let subtract = +oldValue - (+newValue.category_id === category_id ? +newValue.grand_total : 0);
          return newValue.sumtion == 1 ? sumition : subtract;
        }, 0);
      }
    },
    totalCaliberWithoutVat() {
      return (category_id) => {
        return this.reportsRow.reduce((oldValue, newValue) => {
          let sumition = +oldValue + (+newValue.category_id === category_id ? +newValue.price : 0);
          let subtract = +oldValue - (+newValue.category_id === category_id ? +newValue.price : 0);
          return newValue.sumtion == 1 ? sumition : subtract;
        }, 0);
      }
    },
    totalCaliberVat() {
      return (category_id) => {
        return this.reportsRow.reduce((oldValue, newValue) => {
          let sumition = +oldValue + (+newValue.category_id === category_id ? +newValue.total_vat : 0);
          let subtract = +oldValue - (+newValue.category_id === category_id ? +newValue.total_vat : 0);
          return newValue.sumtion == 1 ? sumition : subtract;
        }, 0);
      }
    },
    calculateTotal() {
      return (key) => {
        return this.reportsRow.reduce((oldValue, newValue) => {
          let sumition = +oldValue + +newValue[key];
          let subtract = +oldValue - +newValue[key];
          return newValue.sumtion == 1 ? sumition : subtract;
        }, 0);
      }
    },
  },
  watch: {

  },
  data() {
    return {
      loading: false,

    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
  #printTable{
    font-size: 12px !important;
  }
</style>