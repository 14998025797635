<template>
  <v-app v-resize="onResize">
    <SplashLoader v-if="$store.state.splashScreen" />
    <router-view></router-view>
    <SpeedTest v-if="$store.state.splashScreen == false" />
    <v-slide-y-reverse-transition>
      <template v-if="updateExists && !$store.state.splashScreen">
        <v-snackbar bottom right :value="updateExists" :timeout="0" color="primary">
          <v-row align="center" justify="space-between">
            <v-col cols="auto" class="font-weight-bold">
              {{ $t('new update found') }}
            </v-col>
            <v-col cols="auto">
              <v-btn outlined @click="refreshApp">
                {{ $t('update now') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-snackbar>
      </template>
    </v-slide-y-reverse-transition>
    <UserAlert />
  </v-app>
</template>

<style lang="scss">
@import "./scss/variables.scss";
@import "./scss/core.scss";
</style>
<script>
import SpeedTest from "./components/SpeedTest.vue";
import SplashLoader from "./components/ui/SplashLoader.vue";
import UserAlert from "./components/ui/UserAlert.vue";
import { UpdateSystem } from "@/mixins/UpdateSystem";
export default {
  name: "App",
  mixins: [UpdateSystem],
  components: { SpeedTest, SplashLoader, UserAlert },
  data: () => ({
    registration: null,
    updateExists: false,
    refreshing: false,
  }),
  created() {

  },
  mounted() {

    window.addEventListener("keydown", e => {
      // console.log(e);
      if (e.altKey == true && e.key == "1") {
        if (this.$global.CheckAction("06-008", 1)) {
          this.$router.push('/sales/create-sales-invoice')
        } else {
          this.$router.push('/sales/sales-invoice-Piece')
        }
      }
      if (e.altKey == true && e.key == "2") {
        this.$router.push('/purchases/create-purchases-invoice')
      }
      if (e.altKey == true && e.key == "3") {
        if (this.$global.CheckAction("06-500", 1)) {
          this.$router.push('/return-sales/create-return-sales')
        } else {
          this.$router.push('/return-sales/create-return-sales-piece')
        }
      }
      if (e.altKey == true && e.key == "4") {
        this.$router.push('/notice/create-notice-debtor')
      }
      if (e.altKey == true && e.key == "5") {
        this.$router.push('/notice/create-notice-creditor')
      }
      // if (e.altKey == true && e.key == "1") {
      //   this.$router.push('/')
      // }
      // if (e.altKey == true && e.key == "2") {
      //   this.$router.push('/sales')
      // }
      // if (e.altKey == true && e.key == "3") {
      //   this.$router.push('/sales/create-sales-invoice')
      // }
      // if (e.altKey == true && e.key == "4") {
      //   this.$router.push('/purchases')
      // }
      // if (e.altKey == true && e.key == "5") {
      //   this.$router.push('/purchases/create-purchases-invoice')
      // }
      // if (e.altKey == true && e.key == "6") {
      //   this.$router.push('/return-sales')
      // }
      // if (e.altKey == true && e.key == "7") {
      //   this.$router.push('/return-sales/create-return-sales')
      // }
    });
  },
  methods: {
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },
    onResize() {
      this.$store.state.Settings.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
  },
};
</script>
