<template>
  <section>
    <v-container fluid>
      <v-card class="shadow">
        <v-col cols="12" lg="10" md="11" class="d-flex mx-auto align-center">
          <v-text-field class="my-2 border" outlined background-color="light"
            :placeholder="$i18n.t('search by invoice number')" v-model="search" @keydown.enter="searchReturnSales"
            rounded-md dense autofocus hide-details type="number" @click="searchResult = []"></v-text-field>
          <v-btn @click="searchReturnSales" :disabled="loading" :loading="loading" class="my-2 mx-2 shadow"
            color="primary" :height="40">
            <v-icon dark left>mdi-search-web</v-icon>
            {{ $t('search') }}
          </v-btn>
        </v-col>

        <v-row justify="center" v-if="isLoading">
          <v-col cols="auto">
            <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
              indeterminate></v-progress-circular>
            <p class="text-center">{{ $t('Loading data') }}</p>
          </v-col>
        </v-row>

        <v-form ref="form" v-model="valid" class="pa-5" v-if="searchResult.length > 0">
          <v-card class="backgroundW shadow pa-5">
            <v-row>
              <v-col cols="12" lg="3" sm="6">
                <label>{{ $t('customerName') }}*</label>
                <v-text-field class="my-2" outlined background-color="light" v-model="form.customer_name" rounded-md
                  dense readonly type="text"></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" sm="6">
                <label>{{ $t('customerMobile') }}*</label>
                <v-text-field class="my-2" outlined background-color="light" v-model="form.customer_mobile" rounded-md
                  dense readonly type="number"></v-text-field>
              </v-col>
              <v-col cols="12" lg="2" sm="6">
                <label>{{ $t('Identification Number') }}*</label>
                <v-text-field class="my-2" outlined background-color="light" v-model="form.customer_id_number"
                  rounded-md dense readonly type="number"></v-text-field>
              </v-col>
              <v-col cols="12" lg="2" sm="6">
                <label>{{ $t('Registration tax number') }}*</label>
                <v-text-field class="my-2" outlined background-color="light" v-model="form.customer_vat_register_number"
                  rounded-md dense readonly type="number"></v-text-field>
              </v-col>
              <v-col cols="12" lg="2" sm="6">
                <label>{{ $t('notice date') }}</label>
                <!-- <GenericInput type="switch" :value="invoice_date_type" @input="invoice_date_type = $event" label=" "
                  :required="false" :isLoading="false" :cols="[12, 12, 12]" /> -->
                <div class="mt-2">
                  <AutoCompleteInput background="gray12"
                    :lookups="[{ id: 1, name: $t(`invoice date`) + ` (${invoice_date})` }, { id: 2, name: $t('today') + ` (${today})` }]"
                    selected_label="name" selected_prop="id" :value="invoice_date_type"
                    @input="invoice_date_type = $event" label="" :isLoading="false" :cols="[12, 12, 12]" />
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
        <!-- Table -->
        <v-col cols="12" sm="12" v-if="searchResult.length > 0">
          <v-card class="backgroundW shadow pa-5">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">{{ $t('item') }}</th>
                    <th class="text-center">{{ $t('caliber') }}</th>
                    <th class="text-center">{{ $t('weight') }}</th>
                    <th class="text-center">{{ $t('pieces') }}</th>
                    <th class="text-center">{{ $t('vat') }}</th>
                    <th class="text-center">{{ $t('Total including tax') }}</th>
                    <th class="text-center">{{ $t('edit') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in searchResult" :key="index">
                    <td class="text-center">
                      <v-chip color="success" small class="me-1" v-if="row.item_code" label>{{ row.item_code }}</v-chip>
                      {{ row.sub_category_title }}
                    </td>
                    <td class="text-center">{{ row.category_title }}</td>
                    <td class="text-center">{{ row.current_weight | float }} {{ $t('gram') }}</td>
                    <td class="text-center">{{ row.current_pieces | float }} {{ $t('piece') }}</td>
                    <td class="text-center">{{ row.current_vat | float }} {{ $t('ryal') }}</td>
                    <td class="text-center">{{ row.current_grand_total | float }} {{ $t('ryal') }}</td>
                    <td class="d-flex align-center justify-center" style="height: 70px;">
                      <div class="mx-3">
                        <div class="my-0 d-flex justify-space-between">
                          <div class="mx-2">{{ $t("weight") }} : </div>
                          <div>{{ row.new_weight | float }}</div>
                        </div>
                        <div class="my-0 d-flex justify-space-between">
                          <div class="mx-2">{{ $t("pieces") }} :</div>
                          <div>{{ row.new_pieces | float }}</div>
                        </div>
                        <div class="my-0 d-flex justify-space-between">
                          <div class="mx-2">{{ $t("price") }} :</div>
                          <div>{{ row.new_price | float }}</div>
                        </div>
                      </div>
                      <v-icon v-if="selectValid(row)" color="primary" @click="openDialog(row)"
                        class="cursor_pointer mx-3">
                        mdi-plus-circle
                      </v-icon>

                      <v-icon v-if="row.current_weight && row.item_code"
                        :color="returnedNoticeItems.some(({ d_id }) => d_id == row.d_id) ? 'error' : 'primary'"
                        @click="selectItem(row)" class="cursor_pointer mx-3">
                        {{ returnedNoticeItems.some(({ d_id }) => d_id == row.d_id) ? 'mdi-delete-forever-outline'
                          :
                          'mdi-plus-circle' }}
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

        </v-col>
      </v-card>
      <ReturnNoticeModal :backValueMethod="setData" :setRow="setRow" :dialog="dialog" :row="selectedRow"
        :loading="loading" :noticeType="'creditor'"></ReturnNoticeModal>
      <v-col cols="12" class="px-0 mt-2" v-if="searchResult.length > 0">
        <v-card class="shadow rounded-lg justify-center pa-5">
          <v-row justify="space-between" align="center">
            <v-col cols="auto">
              <v-row>
                <v-col cols="auto" class="px-4">
                  <PaymentMethodNotice :type="payment_type_title" :backValueMethod="setPayment"
                    :machineID="machineId" />
                </v-col>
                <v-col cols="auto" class="px-4">
                  <BankModal :machines="machines" :machine="machineId" :backValueMethod="setMachine" />
                </v-col>
                <v-col cols="auto" class="px-4">
                  <SellarModal :checkChangeAgent="false" :sales_agents="sales_agents" :sellar="salesAgent"
                    :backValueMethod="setAgent" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row align="center">
                <v-col cols="auto">
                  <v-btn @click="openPasswordDialog(1)" :disabled="!canRefundAll" :loading="loading"
                    class="shadow d-block " color="secondary" v-if="searchResult.length > 0">
                    {{ $t('full refund invoice') }}
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn @click="openPasswordDialog(2)" :disabled="!(saveValid)" :loading="loading"
                    class="shadow d-block mx-2" color="success" v-if="searchResult.length > 0">
                    {{ $t('save invoice') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      
      <v-dialog v-model="noteDialog" overlay-opacity="0.75" :width="850">
        <v-card relative flat class="pa-0">
          <img class="d-block ma-auto" src="@/assets/img/png/invoice_warning.webp" width="850" alt="">
        </v-card>
      </v-dialog>




      <v-dialog v-model="passwordDialog.dialog" overlay-opacity="0.75" persistent max-width="452">
        <v-card relative class="backgroundW py-8">

          <v-card-title class="text-h6 mt-3 text-center font-weight-bold">
            <h5 class="d-block primary--text ma-auto">
              <span>{{ $t('branch password') }}</span>
            </h5>
          </v-card-title>


          <v-col cols="12" class="mt-3 px-8">
            <v-row justify="center">

              <v-col cols="11" dir="ltr">
                <v-otp-input dir="ltr" type="number" length="4" autofocus @finish="saveNotice"
                  v-model="passwordDialog.password" />
              </v-col>
              <v-col cols="auto">
                <v-btn @click="passwordDialog.dialog = false" :disabled="loading" :height="52" :width="170" depressed
                  class="rounded-lg font-weight-bold  error--text" color="white">{{ $t('cancel') }}</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" depressed :disabled="!(passwordDialog.password)" :loading="loading"
                  @click="saveNotice" :height="52" :width="170" class="rounded-lg font-weight-bold white--text">
                  {{ $t('submit') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-dialog>

    </v-container>
  </section>
</template>



<script>
import ReturnNoticeModal from "@/components/modals/ReturnNoticeModal.vue";
import PaymentMethodNotice from "@/components/modals/PaymentMethodNotice.vue";
import BankModal from "@/components/modals/BankModal.vue";
import SellarModal from "@/components/modals/SellarModal.vue";
import AutoCompleteInput from "@/components/inputs/AutoCompleteInput.vue";
export default {
  name: "CreditorCreate",
  computed: {
    selectValid() {
      return (row) => {
        return row.category_id <= 25 && !+row.item_code
      }
    },
    saveValid() {
      let items = this.returnedNoticeItems.filter(item => item.new_price || item.new_weight || item.new_price)
      if ((this.payment_type || !this.canSetPayment) && items.length > 0) {
        return true
      } else {
        return false
      }
    },
    totalPaid() {
      return this.payments.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.amount);
      }, 0);
    },
    canRefundAll() {
      const checkAvail = (row) => (+row.current_weight);
      console.log(this.searchResult.every(checkAvail));
      return this.searchResult.every(checkAvail) && this.payment_type
    },
    canSetPayment() {
      return this.returnedNoticeItems.filter((row) => row.new_price > 0).length > 0;
    }
  },
  data: () => ({
    search: '',
    valid: false,
    dialog: false,
    loading: false,
    isLoading: false,
    confirmationDialog: false,
    searchResult: [],
    machineId: null,
    salesAgent: null,
    machines: [],
    sales_agents: [],
    payment_type: null,
    payment_type_title: null,
    key: 1,
    invoice_date_type: null,
    invoice_date: null,
    today: null,
    x: 1,
    form: {
      customer_name: '',
      customer_mobile: '',
      customer_id_number: '',
      customer_vat_register_number: '',
    },
    passwordDialog: {
      password: '',
      type: null,
      dialog: false,
    },
    returnedNoticeItems: [],
    availToReturn: false,
    noteDialog: false,
    selectedRow: {},
    printContent: {},
  }),
  components: {
    ReturnNoticeModal,
    PaymentMethodNotice,
    BankModal,
    SellarModal,
    AutoCompleteInput
  },
  watch: {
    searchResult() {
      this.availToReturn = false;
      this.searchResult.forEach(row => {
        switch (((Number(row.returned_weight) < Number(row.weight)) && this.availToReturn == false)) {
          case true:
            this.availToReturn = true
            break;
        }
      });
    },
    returnedNoticeItems() {
      this.$store.state.InvoiceStore.totalPrice = this.returnedNoticeItems.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.new_price);
      }, 0);
    },
    invoice_date_type() {
      if (this.invoice_date_type == 1) {
        this.form.date = this.invoice_date
      } else {
        this.form.date = this.today
      }
    },
    noteDialog() {
      if (this.noteDialog) {
        setTimeout(() => {
          this.noteDialog = false
        }, 4500);
      }
    }
  },
  mounted() {
    this.getData();
    this.today = this.$global.GetCurrentDate();
  },
  methods: {
    getData() {
      this.$api.GET_METHOD(`get_create_notice_creditor_invoice`).then((response) => {
        if (response.check) {
          this.sales_agents = response.data.sales_agents
          this.machines = response.data.machines
          if (response.data.sales_agents.length > 0) {
            this.salesAgent = response.data.sales_agents[0]
          }
          if (response.data.machines.length > 0) {
            this.machineId = response.data.machines[0]
          }
        }
      })
    },
    setRow(item) {
      if (this.returnedNoticeItems.some((old => old.d_id == item.d_id))) {
        let remove = this.returnedNoticeItems.indexOf((old => old.d_id == item.d_id))
        console.log(remove);
        this.returnedNoticeItems.splice(remove, 1);
        this.returnedNoticeItems.push(item);
      }
      else {
        this.returnedNoticeItems.push(item);
      }
    },
    openPasswordDialog(type) {
      this.passwordDialog.type = type;
      if (this.$store?.state?.userData?.user?.branch_data?.branch_protected) {
        this.passwordDialog.dialog = true;
      } else {
        this.saveNotice();
      }
    },
    saveNotice() {
      if (this.passwordDialog.type == 1) {
        this.returnAll();
      } else {
        this.save();
      }
    },
    returnAll() {
      if (this.canSetPayment ? this.payment_type : true) {
        this.loading = true;
        this.searchResult.forEach(item => {
          item.new_weight = Number(item.current_weight)
          item.new_pieces = Number(item.current_pieces)
          item.new_price = Number(item.current_grand_total)
        });
        let requestBody = {
          items: this.searchResult,
          invoice: this.form.m_id,
          sales_invoice_type: this.$store.state.userData.user.shift.stock_type,
          payment_type: this.payment_type,
          salesAgent: this.salesAgent.id,
          machineId: Number(+this.machineId.id),
          date: this.form.date,
          password: this.passwordDialog.password,
        }
        this.$api.POST_METHOD("create_notice_creditor_invoice", requestBody, true, this.$i18n.t('Invoice Created Successfully')).then((response) => {
          this.loading = false;
          if (response.check) {
            this.search = '';
            this.$refs.form.reset();
            this.searchResult = [];
            this.returnedNoticeItems = [];
            // this.$api.DOWNLOAD_METHOD(`export_notice_creditor/${response.data}?type=pdf`);
            const src = `src="${this.$api.serverUrl}export_notice_creditor/${response.data}?type=image&clientID=${this.$store.state.clientID}"`;
            if (response.data?.barcodes?.length) {
              window.open(`${this.$api.serverUrl}print_barcode?id=${response.data?.barcodes?.toString()}&type=print&clientID=${this.$store.state.clientID}`, '_blank')
            }
            this.passwordDialog.dialog = false;
            this.$api.PrintInvoice(src, response.data);
            this.key = this.key + 1;
            this.$store.state.InvoiceStore.totalPrice = 0;
            this.resetPayment()
          }
        })
      } else {
        this.$swal.fire({
          title: this.$t('Please Check'),
          text: this.$t('please choose payment method'),
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    save() {
      this.loading = true;
      let requestBody = {
        items: this.returnedNoticeItems.filter(item => item.new_price || item.new_weight || item.new_price),
        invoice: this.form.m_id,
        sales_invoice_type: this.$store.state.userData.user.shift.stock_type,
        payment_type: this.payment_type,
        salesAgent: this.salesAgent.id,
        machineId: Number(+this.machineId.id),
        date: this.form.date,
        password: this.passwordDialog.password,
      }

      this.$api.POST_METHOD("create_notice_creditor_invoice", requestBody, true, this.$i18n.t('Invoice Created Successfully')).then((response) => {
        this.loading = false;
        if (response.check) {
          this.search = '';
          this.$refs.form.reset();
          this.searchResult = [];
          this.returnedNoticeItems = [];
          this.passwordDialog.dialog = false;
          this.passwordDialog.password = '';
          const src = `src="${this.$api.serverUrl}export_notice_creditor/${response.data}?type=image&clientID=${this.$store.state.clientID}"`;
          if (response.data?.barcodes?.length) {
            window.open(`${this.$api.serverUrl}print_barcode?id=${response.data?.barcodes?.toString()}&type=print&clientID=${this.$store.state.clientID}`, '_blank')
          }
          this.$api.PrintInvoice(src, response.data);
          this.key = this.key + 1;
          this.$store.state.InvoiceStore.totalPrice = 0;
          this.resetPayment()
        }
      })
    },
    searchReturnSales() {
      if (this.search) {
        this.isLoading = true;
        this.invoice_date_type = null;
        this.$store.state.InvoiceStore.totalPrice = 0;
        let endPoint = this.$store.state.userData.user.shift.stock_type == 1 ? 'get_sales_invoice_by_pieces' : 'get_sales_invoice_by_qty';
        this.$api.GET_METHOD(`${endPoint}/invoice?invoiceCode=${this.search}`).then((response) => {
          this.isLoading = false;
          this.searchResult = []
          response.data?.details?.forEach(row => {
            row.new_price = 0;
            row.new_weight = 0;
            row.new_pieces = 0;
            return this.searchResult.push(row)
          }, 0);
          this.form.m_id = response.data?.master?.m_id
          this.form.customer_name = response.data?.master?.customer_name
          this.form.customer_mobile = response.data?.master?.customer_mobile
          this.form.customer_id_number = response.data?.master?.customer_id_number
          this.form.customer_vat_register_number = response.data?.master?.customer_vat_register_number
          this.invoice_date = response.data?.master?.invoice_date;
          this.form.date = response.data?.master?.invoice_date;
          let invoiceAgent = this.sales_agents?.find(({ id }) => id == response.data?.master?.sales_agent_id) || this.sales_agents[0]
          this.salesAgent = invoiceAgent || {};
          this.invoice_date_type = 2

        })
      } else {
        this.$swal.fire({
          title: this.$t("Please Check"),
          text: this.$t("please write invoice code"),
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    openDialog(row) {
      this.selectedRow = row
      this.dialog = true;
    },
    setData(data, status) {
      console.log("changed data", data);
      this.dialog = status
    },
    setMachine(returnedValue) {
      this.machineId = returnedValue;
    },
    setAgent(returnedValue) {
      this.salesAgent = returnedValue;
    },
    setPayment(returnedValue) {
      this.payment_type = returnedValue;
      switch (returnedValue) {
        case 1:
          this.payment_type_title = 'payCash';
          break;
        case 2:
          this.payment_type_title = 'payBank';
          break;
        case 3:
          this.payment_type_title = 'transferPayment';
          break;

        default:
          break;
      }
    },
    resetPayment() {
      this.payment_type = null;
      this.payment_type_title = null;
    },
    selectItem(row) {
      let returnedItem = { ...row }
      if (this.returnedNoticeItems.some(({ d_id }) => d_id == returnedItem.d_id)) {
        const getIndex = this.returnedNoticeItems.findIndex(({ d_id }) => d_id == returnedItem.d_id)
        this.returnedNoticeItems.splice(getIndex, 1);
      } else {
        returnedItem.new_weight = +returnedItem.current_weight;
        returnedItem.new_pieces = +returnedItem.current_pieces;
        returnedItem.new_price = +returnedItem.current_grand_total;
        this.returnedNoticeItems.push(returnedItem);
      }
    }
  },
};
</script>

<style lang="scss"></style>