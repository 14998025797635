<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="650">
      <v-card relative>
        <v-btn absolute class="mx-2 mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click="backValueMethod">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h6">{{ $t('choose print template') }}</v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-5">
          <v-col cols="12" sm="12">

            <v-row>
              <v-col cols="6" v-for="(template, index) in print_templates" :key="index" class="pa-1">
                <v-btn large block :loading="printLoading" outlined @click="print(template.value)"
                  class="rounded white--text text-right" color="info" elevation="0"
                  style="width: 100%; text-align: start">
                  <!-- <v-icon left>mdi-printer-outline</v-icon> -->
                  {{ template.label }}
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="print_templates.length == 0">
                <v-alert class="d-block ma-auto" dense outlined text type="info">
                  {{ $t('No data available') }}
                </v-alert>
              </v-col>
              <v-col cols="12" class="px-1" v-if="has_whatsapp">
                <v-card outlined class="pa-5">
                  <h6 class="subtitle-1 mb-5 gray4--text">
                    <v-icon color="success">mdi-whatsapp</v-icon>
                    {{ $t('send invoice to customer whatsapp') }}
                  </h6>
                  <v-row align="center">
                    <GenericInput type="textarea" color="success" :dense="true" placeholder="write message content"
                      :value="whatsapp_message" @input="whatsapp_message = $event" label="message content"
                      paddingX="px-0" :required="false" :hide-details="true" :isLoading="isLoading" :cols="[11, 11, 11]"
                      :rowsNumber="4" :autofocus="true" />
                    <v-col cols="1">
                      <v-btn :loading="sendToWhatsappLoading" @click="sendToWhatsapp" depressed color="success"
                        class="d-block  mi-start-auto" icon>
                        <v-icon class="rotate-180" >mdi-send</v-icon>
                        <!-- {{ $t('send invoice') }} -->
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>



<style scoped lang="scss"></style>



<script>
import GenericInput from '../GenericInput.vue';


export default {
  name: "PrintTemplatesModal",
  props: {
    backValueMethod: { default: Function },
    print_templates: { default: [] },
    dialog: { default: false },
    invoice_id: { default: null },
    has_whatsapp: { default: false },
    endpoint: { default: 'export_invoice_sales_qty' },
  },
  computed: {

  },
  components: { GenericInput },
  watch: {
    dialog() {
      if (this.dialog) {
        this.whatsapp_message = `هلا 👋🏼
نرفق لكم الفاتورة رقم ${this.invoice_id}
شكراً لتعاملك معنا ✨
أطيب التحيات، ${this.$store.state.userData.user?.branch_data?.vat_register_name}.`
      }
    }
  },
  mounted() {


  },
  data() {
    return {
      selectedTemplate: null,
      toggle_template: null,
      whatsapp_message: null,
      printLoading: false,
      sendToWhatsappLoading: false,
    };
  },
  methods: {
    print(temp) {
      this.printLoading = true;
      setTimeout(() => {
        this.printLoading = false;
        this.backValueMethod()
      }, 5000);
      const src = `${this.$api.serverUrl}${this.endpoint}/${this.invoice_id}?type=print&clientID=${this.$store.state.clientID}&print_temp=${temp || ''}`;
      this.$api.PrintInvoiceURL(src, this.invoice_id)
    },
    sendToWhatsapp() {
      this.sendToWhatsappLoading = true;
      this.$api.POST_METHOD('whatsapp_sales_invoice', { id: this.invoice_id, message: this.whatsapp_message }).then(({ check }) => {
        this.sendToWhatsappLoading = false;
        if (check) {
          this.whatsapp_message = null;
          this.backValueMethod();
        }
      })
    }
  },
};
</script>
