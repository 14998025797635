<template>
  <v-container fluid class="bgAuth d-flex align-center justify-center pa-0" style="height: 100vh">
    <v-col cols="11" lg="4" md="6" sm="7">
      <CloseShift />
    </v-col>
  </v-container>
</template>
<style scoped lang="scss">
.bgAuth {
  background-image: url("../../assets/img/png/goldBG.png");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
</style>
<script>
import CloseShift from "../../components/modals/CloseShift.vue";
export default {
  name: "ShiftClosePage",
  computed: {

  },
  components: {
    CloseShift
  },
  data() {
    return {

    }
  },
  methods: {
  },
};
</script>
