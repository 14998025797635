<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card relative>
      <v-btn
        absolute
        class="mt-2"
        :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'"
        small
        fab
        color="grey lighten-1"
        text
        @click="closeDialog"
      >
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="d-flex justify-space-between py-0">
        <v-col cols="auto">
          <v-row>
            <v-col cols="auto">
              <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ gold.item }}</h6>
              <h6 class="my-0">
                <strong>{{ $t('caliber') }} {{ gold.caliber }}</strong>
              </h6>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-row>
            <v-col cols="auto">
              <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
            </v-col>
            <v-col cols="auto">
              <h6 class="text--disabled">{{ $t('weight') }}</h6>
              <h6 class="my-0">
                <strong>{{ $global.DecimalFormatter(Number(gold.weight).toFixed(2)) }} {{ $t('gram') }}</strong>
              </h6>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <div class="pa-5 pt-0">
        <v-col cols="12" sm="11" class="ma-auto">
          <div class="d-flex justify-space-between align-center">
            <h5 class="my-1">{{ $t('pleaseEnterThePrice') }}</h5>
            <div class="text-end">
              <h5
                class="my-1 text--disabled"
              >{{ $t('lessPrice') }} : {{ $global.DecimalFormatter(gold.lessPrice) }}</h5>
              <h5
                class="my-1 text--disabled"
              >{{ $t('priceOriginal') }} : {{ $global.DecimalFormatter(gold.basePrice) }}</h5>
            </div>
          </div>
          <NumberPad :backValueMethod="setPrice" :clickEnter="setData" :value="price"></NumberPad>
        </v-col>

        <v-col cols="11" class="py-0 ma-auto">
          <v-btn
            color="success"
            large
            :disabled="!valid"
            @click="setData"
            class="shadow"
            style="width: 100%"
          >{{ $t('ok') }}</v-btn>
        </v-col>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import NumberPad from "../NumberPad.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "ItemModalPieces",
  props: {
    closeDialog: { type: Function },
    gold: { default: {} },
    dialog: { default: false },
    vats: { default: [] }
  },
  computed: {
    ...mapState(["bill", "customerType"]),
    valid() {
      if (this.customerType == 1) {
        if (Number(this.price) >= Number(this.gold.lessPrice) && Number(this.gold.lessPrice) !== 0) {
          return true
        }
        else {
          return false
        }
      }
      else {
        if (Number(this.gold.lessPrice) !== 0) {
          return true
        } else {
          return false
        }
      }
    },
  },
  components: {
    NumberPad,
  },
  methods: {
    ...mapActions(["addBillAction"]),
    setPrice(returnedPrice) {
      this.price = returnedPrice;
      this.itemPriceWithoutVat = Number(returnedPrice) / (1 + Number(this.vats[this.gold.caliber]));
      this.itemVat = Number(returnedPrice) - this.itemPriceWithoutVat;
      this.summary = {
        itemCode: this.gold.item_code,
        itemTitle: this.gold.item,
        categoryId: this.gold.caliber,
        sub_category_id: this.gold.sub_category_id,
        gold_weight: this.gold.weight,
        minPrice: Number(this.gold.lessPrice),
        priceWithoutVat: Number(+this.itemPriceWithoutVat),
        vat: this.itemVat,
        price: Number(returnedPrice),
        caliber_price: this.gold.caliber_price || 0,
        countPieces: 1
      }
    },
    setData() {
      if (this.valid == true) {
        this.addBillAction(this.summary).finally(() => {
          this.summary = {}
          this.price = ''
          this.closeDialog()
        });
      }
    },
  },
  watch: {},
  data() {
    return {
      summaryDialog: false,
      price: "",
      itemPriceWithoutVat: 0,
      itemVat: 0,
      summary: {}
    };
  },
};
</script>