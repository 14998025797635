<template>
  <v-container fluid class="px-0">
    <SystemAlert />
    <v-row justify="space-between" align="center">
      <v-col col="12" lg="auto" md="auto" sm="10">
        <v-row align="center">

          <v-col cols="auto" class="px-5">
            <router-link to="/">
              <img src="../assets/img/png/logo.png" style="max-height: 70px;" alt="Qarat-POS" />
            </router-link>
          </v-col>


          <v-col cols="auto" class="d-md-block d-none px-1" v-if="salesPermission == true">
            <v-btn to="/sales" active-class="primary" style="height: 40px" rounded :min-width="85" small
              color="transparent" depressed>{{ $t('sales') }}</v-btn>
          </v-col>

          <v-col cols="auto" class="d-md-block d-none px-1" v-if="purchasePermission == true">
            <v-btn to="/purchases" active-class="primary" rounded :min-width="85" style="height: 40px" small
              color="transparent" depressed>{{ $t('usedInvoice') }}</v-btn>
          </v-col>

          <v-col cols="auto" class="d-md-block d-none px-1" v-if="UsedPurchasePermission == true">
            <v-btn to="/used-purchases" active-class="primary" rounded :min-width="85" style="height: 40px" small
              color="transparent" depressed>{{ $t('used purchases') }}</v-btn>
          </v-col>

          <v-col cols="auto" class="d-md-block d-none px-1" v-if="returnPermission == true">
            <v-btn to="/return-sales" active-class="primary" rounded :min-width="85" style="height: 40px" small
              color="transparent" depressed>{{ $t('return sales') }}</v-btn>
          </v-col>

          <v-col cols="auto" class="d-md-block d-none px-1" v-if="noticePermission">
            <v-btn to="/notice" active-class="primary" rounded :min-width="85" style="height: 40px" small
              color="transparent" depressed>{{ $t("notices") }}</v-btn>
          </v-col>

          <v-col cols="auto" class="d-md-block d-none px-1" v-if="goldPermission">
            <v-btn to="/permission" active-class="primary" rounded :min-width="85" style="height: 40px" small
              color="transparent" depressed>{{ $t("gold permission") }}</v-btn>
          </v-col>



          <v-col cols="auto" class="d-md-block d-none px-1" v-if="expensesPermission">
            <v-btn to="/expenses" active-class="primary" rounded :min-width="85" style="height: 40px" small
              color="transparent" depressed>{{ $t("expenses") }}</v-btn>
          </v-col>

          <v-col cols="auto" class="d-md-block d-none px-1" v-if="depositPermission">
            <v-btn to="/deposits" active-class="primary" rounded :min-width="85" style="height: 40px" small
              color="transparent" depressed>{{ $t("deposits") }}</v-btn>
          </v-col>

          <v-col cols="auto" class="d-md-block d-none px-1" v-if="reportsPermission">
            <v-btn to="/reports" active-class="primary" rounded :min-width="85" style="height: 40px" small
              color="transparent" depressed>{{ $t("reports") }}</v-btn>
          </v-col>

          <v-col cols="auto" class="d-md-block d-none px-1" v-if="transfersReportPermission">
            <v-btn to="/transfers" active-class="primary" rounded :min-width="85" style="height: 40px" small
              color="transparent" depressed>{{ $t("transfers") }}</v-btn>
          </v-col>



        </v-row>
      </v-col>
      <v-col col="12" lg="auto" md="auto" sm="2">
        <v-row align="center">
          <v-col cols="12" md="auto" class="d-lg-block d-none" sm="4">
            <v-btn small rounded class="bg-white" style="height: 40px" to="/close-shift" depressed>
              <img class="mx-1" src="../assets/img/svg/logout.svg" alt="Qarat-POS" />
              <span class="d-lg-block d-none">{{ $t('endShift') }}</span>
            </v-btn>
          </v-col>

          <!-- <v-col cols="12" md="auto" class="d-lg-block d-none" sm="4">
            <v-btn v-on:click="changeLanguage" small fab class="bg-white d-block ma-auto" elevation="0">
              <img src="../assets/img/svg/USA.svg" height="25" v-if="$vuetify.rtl == true" alt="Qarat-POS" />
              <img src="../assets/img/svg/KSA.svg" height="25" v-if="$vuetify.rtl == false" alt="Qarat-POS" />
            </v-btn>
          </v-col> -->
          <!-- <v-col cols="12" md="auto" class="d-lg-block d-none" sm="4">
            <v-btn small fab elevation="0" v-fullscreen.teleport="options">
              <v-icon v-if="!fullscreen">mdi-fullscreen</v-icon>
              <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
            </v-btn>
          </v-col> -->
          <v-col cols="12" md="auto" sm="4">
            <AppbarMenu />
          </v-col>
        </v-row>
      </v-col>
      <small id="testingVersion" v-if="appName == 'qarat_pos_test'">Testing Version</small>
    </v-row>
  </v-container>
</template>




<script>
import AppbarMenu from "./AppbarMenu.vue";
import SystemAlert from "./SystemAlert.vue";
export default {
  name: "Appbar",
  computed: {
    salesPermission() {
      return this.$global.CheckAction("06-001", 1) || this.$global.CheckAction("06-008", 1)
    },
    purchasePermission() {
      return this.$global.CheckAction("06-100", 1)
    },
    UsedPurchasePermission() {
      return this.$global.CheckAction("04-008", 1)
    },
    returnPermission() {
      return this.$global.CheckAction("06-500", 1) || this.$global.CheckAction("06-500", 1)
    },
    noticePermission() {
      return (this.$global.CheckAction("06-003", 1) || this.$global.CheckAction("06-002", 1))
    },
    expensesPermission() {
      return this.$global.CheckAction("06-101", 1)
    },
    reportsPermission() {
      return (this.$global.CheckAction("06-014", 1) || this.$global.CheckAction("06-018", 1) || this.$global.CheckAction("02-020", 1) || this.$global.CheckAction("06-010", 1) || this.$global.CheckAction("06-012", 1) || this.$global.CheckAction("03-004", 1))
    },
    transfersReportPermission() {
      return (this.$global.CheckAction("06-021", 1) || this.$global.CheckAction("06-022", 1))
    },
    goldPermission() {
      return (this.$global.CheckAction("02-014", 1) || this.$global.CheckAction("02-015", 1))
    },
    depositPermission() {
      return this.$global.CheckAction("06-023", 1)
    },
    appName(){
      return process?.env?.VUE_APP_PROJECT
    },
    options() {
      return {
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen
        },
        target: '.directive-fullscreen-wrapper',
        pageOnly: this.pageOnly,
        teleport: this.teleport
      }
    }
  },
  data() {
    return {
      shiftOpen: false,
      fullscreen: false,
    };
  },
  components: {
    AppbarMenu,SystemAlert
  },

  methods: {
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>
<style scoped>
#testingVersion {
  position: absolute;
  bottom: -22px;
  left: 0;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  background: red;
  padding: 2px;
}
</style>