<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between" class="my-0 mt-n3">
        <v-col cols="12" class="d-flex justify-center align-center py-0 mb-2">
          <v-row align="center" justify="space-between">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center me-3 text--disabled">
                <strong>{{ $t('used purchases') | capitalize }} . {{ $t('invoice number') }} .
                  {{ $route.params.id }}</strong>
              </div>
            </v-col>
            <v-col cols="12" md="auto">
              <v-btn color="blue2" v-if="$store.state.userData.super_admin == 1 && !isLoading" class="blue2--text mx-2"
                :width="120" outlined depressed
                @click="$router.push(`/used-purchases/edit-purchases-invoice/${master.id}`)">
                {{ $t('edit') }}
                <v-icon right>mdi-pen</v-icon>
              </v-btn>
              <v-btn color="red12" class="error--text mx-2" :width="120" depressed @click="exitDialog = true">
                {{ $t('cancel') }}
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn color="success" :disabled="!(saveIsValid && details.length)" :width="120" :loading="saveLoading"
                depressed @click="save">
                {{ $t('save') }}
                <v-icon right>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="!isLoading">
        <v-col cols="12" class="pa-0 mt-n4" v-if="details.length">
          <ControlSummery :summery="summery" :loading="isLoading" />
        </v-col>
        <v-col cols="12" class="pa-0 px-3 mt-n4" v-if="details.length">
          <DynamicTable :hideExtinctions="true" :isLoading="false" :data="details" tableHeight="340" :showHeader="false"
            :header="header" :footerData="[computedItem]">
            <template v-slot:td="{ row, index, header }">
              <div v-if="header.key == 'actions'">
                <v-btn v-if="row.status == 1" @click="openDetail(row, 'edit', index)" color="blue1"
                  class="white--text shadow" small>
                  {{ $t('cracking') }}
                </v-btn>
                <div class="font-weight-bold success--text" v-else>
                  {{ $t('cracking is done') }}
                </div>
              </div>
            </template>
          </DynamicTable>
        </v-col>
        <v-col cols="12" class="mt-n3" v-else>
          <v-card class="white rounded-lg shadow pa-5 overflow-y-scroll" max-height="380px">
            <v-alert class="d-block ma-auto" dense outlined text type="info">{{ $t('no items added') }}</v-alert>
          </v-card>
        </v-col>



        <v-col cols="12" v-if="attachments.length" >
          <v-card class="borderedCard pa-3" flat>
            <h6 class="subtitle-1 mb-3 font-weight-bold">{{ $t('attachments') }}</h6>
            <v-row>
              <v-col cols="auto" class="imgContainer" v-for="(file, index) in attachments" :key="index">
                <v-img max-width="115px" width="115px" max-height="115px"
                  :src="$store.state.endpointURL + file.file_path" alt="" />
                <div class="caption text-center d-block text-truncate" style="max-width: 115px;">
                  {{ file.file_name }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>




      </v-row>

      <v-row justify="center" style="height: 90vh;" align="center" v-if="isLoading">
        <v-col cols="auto">
          <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
            indeterminate></v-progress-circular>
          <p class="text-center">{{ $t('Loading data') }}</p>
        </v-col>
      </v-row>
    </v-container>
    <UsedItemModal :dialog="detailDialog" :detail="activeDetail" :closeDialog="() => detailDialog = false"
      :setDetail="setDetail" :isEdit="true" :editDetail="editDetail" />

    <ExitConfirmationModal :dialog="exitDialog" :closeDialog="() => exitDialog = false" exit_to="/used-purchases" />
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import ExitConfirmationModal from "@/components/modals/ExitConfirmationModal.vue";
import UsedItemModal from "@/components/modals/UsedItemModal.vue";
import ControlSummery from "@/components/ui/ControlSummery.vue";
export default {
  name: "UsedPurchasesInvoicesControl",

  data: () => ({
    isLoading: true,
    selectedAgent: {},
    header: [],
    details: [],
    master: Object,
    detailDialog: false,
    activeDetail: Object,
    exitDialog: false,
    saveLoading: false,
    editDetail: false,
    summery: [],
    attachments: [],
  }),
  components: {
    DynamicTable,
    UsedItemModal,
    ExitConfirmationModal,
    ControlSummery
  },
  computed: {
    computedItem() {
      return {
        price: this.$global.ARRAY_REDUCE(this.details || [], 'price'),
        weight: this.$global.ARRAY_REDUCE(this.details || [], 'weight'),
        used_weight: this.$global.ARRAY_REDUCE(this.details || [], 'used_weight'),
        diff_weight: this.$global.ARRAY_REDUCE(this.details || [], 'diff_weight'),
        other_materials_weight: this.$global.ARRAY_REDUCE(this.details || [], 'other_materials_weight'),
      }
    },
    saveIsValid() {
      return this.details.some((item) => +item.used_weight > 0 && item.status == 1)
    }
  },
  watch: {
    detailDialog() {
      if (!this.detailDialog) {
        this.activeDetail = Object;
        this.editDetail = false
      }
    }
  },
  mounted() {
    if (!this.$global.CheckAction("04-008", 1)) {
      this.$router.push('/login')
    } else {
      this.isLoading = true;
      this.$api.GET_METHOD(`get_used_purchase/${this.$route.params.id}`)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {
            response.data.details.forEach(row => {
              row.weight = row.raw_weight;
              row.used_weight = row.used_weight || 0
              row.price = row.total_price;
            });
            response.data.item.payments = response.data.payments || [];
            response.data.machine_id = response.data.payments.filter(({ machine_id }) => machine_id)[0]?.machine_id || 0;
            this.master = response.data.item || Object;
            let hijriDate = this.master.birth_date_h?.split('/')?.reverse()?.join('-');
            this.master.birth_date_h = hijriDate
            this.details = response.data.details || [],
              this.summery = [
                {
                  label: 'invoice date',
                  value: response.data.item.action_date,
                  class: '',
                  cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
                },
                {
                  label: 'customer',
                  value: response.data.item.customer_name,
                  class: '',
                  cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
                },
                {
                  label: 'idNumber',
                  value: response.data.item.customer_id_number,
                  class: '',
                  cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
                },
                {
                  label: 'customer mobile',
                  value: response.data.item.customer_mobile,
                  class: '',
                  cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
                },
                {
                  label: 'customer email',
                  value: response.data.item.customer_email,
                  class: '',
                  cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
                },
                {
                  label: 'branch',
                  value: response.data.item.branch_name,
                  class: '',
                  cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
                },
                {
                  label: 'hijri',
                  value: response.data.item.birth_date_h,
                  class: '',
                  cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
                },
                {
                  label: 'georgian',
                  value: response.data.item.birth_date_m,
                  class: '',
                  cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
                },
                {
                  label: 'added by',
                  value: response.data.item.user_full_name,
                  class: '',
                  cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
                },
                {
                  label: 'description',
                  value: response.data.item.description,
                  class: '',
                  cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
                },
              ];
            this.master.id_photo ? this.attachments.push({ file_path: this.master.id_photo }) : null;
            this.master.item_photo ? this.attachments.push({ file_path: this.master.item_photo }) : null;
            this.getAttachments();
          }
        })
    }

    this.header = [
      { text: "#", key: "index", type: 'index', classes: "" },
      { text: "caliber", key: "category_title", type: 'text', classes: "" },
      { text: "item", key: "sub_category_title", type: 'text', classes: "" },
      { text: "description", key: "description", type: 'text', classes: "" },
      { text: "price", key: "price", type: 'float', classes: "" },
      { text: "weight before cracking", key: "weight", type: 'weight', classes: "" },
      { text: "lobes weight", key: "other_materials_weight", type: 'weight', classes: "" },
      { text: "estimated weight", key: "diff_weight", type: 'weight', classes: "" },
      { text: "weight after cracking", key: "used_weight", type: 'weight', classes: "" },
      { text: "actions", key: "actions", type: 'slot', classes: "" },
    ];

  },
  methods: {
    openDetail(detail, type, index) {
      this.activeDetail = Object
      console.log(detail, type, index);
      if (type == 'new') {
        let newDetail = Object;
        newDetail.d_id = 0;
        newDetail.category_id = detail.category_id;
        newDetail.sub_category_title = detail.sub_category_title;
        newDetail.sub_category_id = detail.sub_category_id;
        this.activeDetail = { ...newDetail };
        this.detailDialog = true;
      } else {
        this.activeDetail = { ...detail };
        this.activeDetail.index = index;
        this.detailDialog = true;
        this.editDetail = true
      }
    },
    setDetail(detail) {
      if (this.editDetail) {
        console.log(detail);
        this.details[detail.index] = { ...detail }
        this.details = [...this.details]
      } else {
        this.details.push({ ...detail })
      }
      this.detailDialog = false;
      this.editDetail = false
    },
    removeDetail(index) {
      this.details.splice(index, 1);
    },
    openImage(image) {
      window.open(this.$store.state.endpointURL.slice(0, -1) + image, 'Image', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1')
    },
    save() {
      this.saveLoading = true;
      let crackingItems = []
      this.details.forEach(item => {
        if (item.used_weight > 0 && item.status == 1) {
          let obj = {
            id: item.id || item.d_id,
            d_id: item.d_id,
            used_weight: item.used_weight,
            weight: item.weight,
          }
          crackingItems.push(obj)
        }
      });
      this.$api.POST_METHOD(`update_used_status/${this.$route.params.id}`, { items: crackingItems }).then((response) => {
        this.saveLoading = false;
        if (response.check) {
          this.$router.push('/used-purchases')
        }
      })
    },
    getAttachments() {
      let requestBody = { screen_code: '04-008', m_id: +this.$route.params.id }
      this.$api.GET_METHOD(`attachment?screen_code=${requestBody.screen_code}&m_id=${requestBody.m_id}`).then((response) => {
        if (response.check) {
          response.data?.files.forEach(file => {
            file.file_path = '/upload/04-008/' + file.file_path
            this.attachments.push(file)
          });
        }
      })
    },
  },
};
</script>
